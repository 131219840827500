import { useEffect, useState/*, useCallback*/ } from 'react';
import { GoogleMap, /*LoadScript,*/ useLoadScript, KmlLayer, Marker, Polygon, Polyline, Circle, InfoWindow, DrawingManager } from '@react-google-maps/api';
import Accordion from 'react-bootstrap/Accordion';
import Axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import "bootstrap/dist/css/bootstrap.min.css";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Swal from 'sweetalert2'
import { CSVLink } from "react-csv";
import { Chart } from "react-google-charts";
//import Draggable from 'react-draggable';


function checkCircleInMarker(markerPosition, circlePosition) {
  let km = circlePosition.radius/1000;
  let kx = Math.cos(Math.PI * circlePosition.lat / 180) * 111;
  let dx = Math.abs(circlePosition.lng - markerPosition.lng) * kx;
  let dy = Math.abs(circlePosition.lat - markerPosition.lat) * 111;
  return Math.sqrt(dx * dx + dy * dy) <= km;
}

/*
let navInfo = window.navigator.appVersion.toLowerCase();
let so = 'Sistema Operativo';
if(navInfo.indexOf('win') != -1){
	so = 'Windows';
}else if(navInfo.indexOf('linux') != -1){
	so = 'Linux';
}else if(navInfo.indexOf('mac') != -1){
	so = 'Macintosh';
}
alert('lol'+so)
*/
let navegador = navigator.userAgent;
if(navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)){
  navegador=1
}else{
  navegador=0
}

const libraries = ['drawing']

const alerta = (tipo,titulo) =>{
	Swal.fire({
		type: tipo,
		title: titulo
	});
}

const center = {lat:20.4303641968, lng:-99.0867285835}
const centerDos = {lat:20.4303641969, lng:-99.0867285836}
const mi_zoom = 8
const mi_zoomDos = 8.001

function Carta(props) {
	const [menu, setMenu] = useState(false);
	const [modal, setModal] = useState(false);
	const [buscar, setBuscar] = useState("");
	const [graficasModal, setGraficasModal] = useState(false);
	const [informacion, setInformacion] = useState("");
	const [fotografia, setFotografia] = useState("");
	const [region, setRegion] = useState("");
	const [centro, setCentro] = useState(center);
	const [zoom, setZoom] = useState(mi_zoom);
	const [coordenadasMarker, setCoordenadasMarker] = useState({});
	const [requisitos, setRequisitos] = useState(1);

	const [valoresDraw, setValoresDraw] = useState([]);
	const [drawSuma, setDrawSuma] = useState(0);

	const [modalParque, setModalParque] = useState(0);
	/*
	const renderKml = useCallback(() => {
		return(
			
		)}
	)*/
	/*
	const [capaArco, setCapaArco] = useState("");
	const funcionCapaArco = () => {
	  if(!capaArco){
	  	setCapaArco(<KmlLayer url="http://148.223.224.75/mapa/capas/arco.kml" zIndex={2}/>)
	  }else{
	  	setCapaArco("") 
	  }
	}
	*/

	const [activeMarker, setActiveMarker] = useState(null);
	/***Información básica***/
	const [Capa_limite, setCapa_limite] = useState(1);//eslint-disable-line
	const [Capa_Hidalgo, setCapa_Hidalgo] = useState(1);//eslint-disable-line
	const [Capa_capital, setCapa_capital] = useState(0);//eslint-disable-line
	const [Capa_urbana, setCapa_urbana] = useState(0);//eslint-disable-line
	const [Capa_MX, setCapa_MX] = useState(0);//eslint-disable-line
	const [Capa_macro, setCapa_macro] = useState(0);//eslint-disable-line
	const [Capa_region, setCapa_region] = useState(0);//eslint-disable-line
	const [Capa_micro, setCapa_micro] = useState(0);//eslint-disable-line
	/***Conectividad y logística***/
	const [Capa_carf, setCapa_carf] = useState(0);//eslint-disable-line
	const [Capa_care, setCapa_care] = useState(0);//eslint-disable-line
	const [Capa_arco, setCapa_arco] = useState(0);//eslint-disable-line
	const [Capa_ai, setCapa_ai] = useState(0);//eslint-disable-line
	const [Capa_psa, setCapa_psa] = useState(0);//eslint-disable-line
	/***Conectividad y logística***/
	const [Capa_ferrea, setCapa_ferrea] = useState(0);//eslint-disable-line
	const [Capa_ferreamex, setCapa_ferreamex] = useState(0);//eslint-disable-line
	const [Capa_station, setCapa_station] = useState(0);//eslint-disable-line
	const [Capa_terminal, setCapa_terminal] = useState(0);//eslint-disable-line
	const [Capa_transload, setCapa_transload] = useState(0);//eslint-disable-line
	/***Medio ambiente***/
	const [Capa_anp, setCapa_anp] = useState(0);//eslint-disable-line
	/***Educación***/
	const [Capa_ciudad, setCapa_ciudad] = useState(0);//eslint-disable-line
	const [Capa_cientifico, setCapa_cientifico] = useState(0);//eslint-disable-line
	//show_escuelas
	const [arrayEsc, setArrayEsc] = useState([]);
	const [carreraEscuelas, setCarreraEscuelas] = useState([]);
	const [regionEscuelas, setRegionEscuelas] = useState([]);
	const [municipioEscuelas, setMunicipioEscuelas] = useState([]);
	const [institucionEscuelas, setInstitucionEscuelas] = useState([]);
	//const [escuelaEscuelas, setEscuelaEscuelas] = useState([]);
	let condicion_escuelas = `p.es_id`
	if(carreraEscuelas.length>0){
		condicion_escuelas += `&& (`
		carreraEscuelas.map((item, i) => { //eslint-disable-line
			let or = " || "
			if(i===0){
				or = ""
			}
			condicion_escuelas += `${or}p.es_carreras.filter(t => t.es_carrera==='${item}').length>0`
		})
		condicion_escuelas += `)`
	}
	if(institucionEscuelas.length>0){
		condicion_escuelas += `&& (`
		institucionEscuelas.map((item, i) => { //eslint-disable-line
			let or = " || "
			if(i===0){
				or = ""
			}
			condicion_escuelas += `${or}p.es_institucion==='${item}'`
		})
		condicion_escuelas += `)`
	}
	/*
	if(escuelaEscuelas.length>0){
		condicion_escuelas += `&& (`
		escuelaEscuelas.map((item, i) => { //eslint-disable-line
			let or = " || "
			if(i===0){
				or = ""
			}
			condicion_escuelas += `${or}p.es_escuela==='${item}'`
		})
		condicion_escuelas += `)`
	}
	*/
	if(municipioEscuelas.length>0){
		condicion_escuelas += `&& (`
		municipioEscuelas.map((item, i) => { //eslint-disable-line
			let or = " || "
			if(i===0){
				or = ""
			}
			condicion_escuelas += `${or}p.municipio==='${item}'.toUpperCase()`
		})
		condicion_escuelas += `)`
	}
	/***Indicadores***/
	//show_indicadores
	const [indicadores, setIndicadores] = useState("");
	const [indicadorPrincipal, setIndicadorPrincipal] = useState("");
	const [arrayIndicadores, setArrayIndicadores] = useState([]);
	const [indicadoresVariable, setIndicadoresVariable] = useState([]);
	const [indica, setIndica] = useState([]);
	const [indicadoresRegion, setIndicadoresRegion] = useState([]);
	const [indicadoresMunicipio, setIndicadoresMunicipio] = useState([]);
	let condicion_indicadores = `p.id>0`
	if(indicadoresMunicipio.length>0){
		condicion_indicadores += `&& (`
		indicadoresMunicipio.map((item, i) => { //eslint-disable-line
			let or = " || "
			if(i===0){
				or = ""
			}
			condicion_indicadores += `${or}p.municipio==='${item}'`
		})
		condicion_indicadores += `)`
	}
	//fin show_indicadores
	/***Vocaciones económicas***/
	//show_comercios
	const [arrayDenue, setArrayDenue] = useState([]);
	const [denueRapida, setDenueRapida] = useState("");
	const [denueRegion, setDenueRegion] = useState([]);
	const [denueMunicipio, setDenueMunicipio] = useState([]);
	const [denueActividad, setDenueActividad] = useState([]);
	const [denueTamano, setDenueTamano] = useState([]);

	const [arrayMunicipiosDenue, setArrayMunicipiosDenue] = useState([]);
	const [mayorDenue, setMayorDenue] = useState(0);
	//fin show_comercios
	//show_laboral
	const [arrayLaboral, setArrayLaboral] = useState([]);
	const [laboralFechaAno, setLaboralFechaAno] = useState(window.ano);
	const [laboralFechaMes, setLaboralFechaMes] = useState(0);
	const [laboralRegion, setLaboralRegion] = useState([]);
	const [laboralMunicipio, setLaboralMunicipio] = useState([]);
	const [laboralActividad, setLaboralActividad] = useState([]);
	const [laboralTipo, setLaboralTipo] = useState([]);
	//fin show_laboral
	/***Industria***/
	const [Capa_parques, setCapa_parques] = useState(0);//eslint-disable-line
	const [Capa_zonas, setCapa_zonas] = useState(0);//eslint-disable-line
	const [Capa_corredores, setCapa_corredores] = useState(0);//eslint-disable-line
	/***Infraestructura***/
	const [Capa_subelectrica, setCapa_subelectrica] = useState(0);//eslint-disable-line
	const [Capa_electrica, setCapa_electrica] = useState(0);//eslint-disable-line
	const [Capa_tm, setCapa_tm] = useState(0);//eslint-disable-line
	const [Capa_gas, setCapa_gas] = useState(0);//eslint-disable-line
	const [Capa_oleoductos, setCapa_oleoductos] = useState(0);//eslint-disable-line
	const [Capa_poliductos, setCapa_poliductos] = useState(0);//eslint-disable-line
	const [Capa_otrosductos, setCapa_otrosductos] = useState(0);//eslint-disable-line
	/***Infraestructura***/
	const [Capa_aeeh, setCapa_aeeh] = useState(0);//eslint-disable-line
	/***Cobertura Móvil***/
	const [Capa_2g_t, setCapa_2g_t] = useState(0);//eslint-disable-line
	const [Capa_3g_t, setCapa_3g_t] = useState(0);//eslint-disable-line
	const [Capa_4g_t, setCapa_4g_t] = useState(0);//eslint-disable-line
	const [Capa_3g_a, setCapa_3g_a] = useState(0);//eslint-disable-line
	const [Capa_4g_a, setCapa_4g_a] = useState(0);//eslint-disable-line
	const [Capa_2g_m, setCapa_2g_m] = useState(0);//eslint-disable-line
	const [Capa_3g_m, setCapa_3g_m] = useState(0);//eslint-disable-line
	const [Capa_4g_m, setCapa_4g_m] = useState(0);//eslint-disable-line
	/***México Conectado***/
	//const [Capa_iTelmex, setCapa_iTelmex] = useState(0);//eslint-disable-line
	//const [Capa_iTotal, setCapa_iTotal] = useState(0);//eslint-disable-line
	/***Otros***/
	const [Capa_ganaderia, setCapa_ganaderia] = useState(0);//eslint-disable-line
	//show_mercados
	const [arrayMercados, setArrayMercados] = useState([]);
	const [mercadoTipo, setMercadoTipo] = useState("mercados");
	const [mercadoNombre, setMercadoNombre] = useState("");
	const [mercadoRegion, setMercadoRegion] = useState([]);
	const [mercadoMunicipio, setMercadoMunicipio] = useState([]);
	let condicion_mercados = `p.tipo==='${mercadoTipo}'`
	if(mercadoNombre.length>0){
		condicion_mercados += `&& (`
		mercadoNombre.map((item, i) => { //eslint-disable-line
			let or = " || "
			if(i===0){
				or = ""
			}
			condicion_mercados += `${or}p.nombre==='${item}'`
		})
		condicion_mercados += `)`
	}
	if(mercadoMunicipio.length>0){
		condicion_mercados += `&& (`
		mercadoMunicipio.map((item, i) => { //eslint-disable-line
			let or = " || "
			if(i===0){
				or = ""
			}
			condicion_mercados += `${or}p.municipio==='${item}'`
		})
		condicion_mercados += `)`
	}
	//Fin show_mercados
	const [Capa_Magicos, setCapa_Magicos] = useState(0);//eslint-disable-line
	const [Capa_Presidencias, setCapa_Presidencias] = useState(0);//eslint-disable-line
	const [Capa_agua, setCapa_agua] = useState(0);//eslint-disable-line
	const [Capa_corrientea, setCapa_corrientea] = useState(0);//eslint-disable-line
	/*
	const funcionKml = (capa, kml, posicion) => {
	  
		//alert(`set${capa}(<KmlLayer url="${kml}" zIndex={${posicion}}/>)`)

		let condicion = eval(`!${capa}`)
		if(condicion){
  		//alert('no')
  		//setCapa_urbana(<KmlLayer url={kml} zIndex={posicion}/>)
  		eval(`set${capa}('<KmlLayer url="${kml}"/>')`)
  	}else{
  		//setCapa_urbana("")
  		eval(`set${capa}("")`)
  		//alert('si')
  	}
	  /*
	  if(capa==='Capa_urbana'){
	  	if(!Capa_urbana){
	  		setCapa_urbana(<KmlLayer url={kml} zIndex={posicion}/>)
	  	}else{
	  		setCapa_urbana("")
	  	}
	  }
	  */
	//}

	const functionZoom = () => {
		if(centro.lat===center.lat){
			setCentro(centerDos)
		}else{
			setCentro(center)
		}
		if(zoom===mi_zoom){
			setZoom(mi_zoomDos)
		}else{
			setZoom(mi_zoom)
		}
	}

	//Para mapear color
	const [queson, setQueson] = useState("");
	let cantidad_repetido
	let unique_municipio = []
	unique_municipio = [...new Set(arrayEsc.map(item => item.municipio))];
	let unique_municipio_mercados = []
	unique_municipio_mercados = [...new Set(arrayMercados.map(item => item.municipio))];
	if(queson==='escuelas'){
	  let ids = arrayEsc.map(user => user.municipio)
	  let cantina = ids.sort( // ordena de dos en dos
	    (id1, id2) => // primer id a comparar, segundo id a comparar
	    ids.filter(valor => valor === id1).length // veces que aparece id1
	    -
	    ids.filter(valor => valor === id2).length //veces que aparecer id2
	  ).pop() // saca al primero que halles que tenga mas apariciones
		cantidad_repetido = arrayEsc.filter(p => p.municipio===cantina).length
	}else if(queson==='trabajadores'){
		if(arrayLaboral.length>0){
	  	cantidad_repetido = arrayLaboral.slice(0, 1)[0].total
		}
	}else if(queson==='establecimientos'){
		cantidad_repetido = mayorDenue
	}else if(window._my_indicadores.filter(p => p.nombre===queson).length>0){
		if(indica.length>0){
			var mi_evaluados = ""
			indica.map((item, i) => { //eslint-disable-line
				var sumada = ""
				if(i>0){
					sumada = "+"
				}
				mi_evaluados += `${sumada}item.${item.key}`
			})
			cantidad_repetido =  Math.max(...arrayIndicadores.map(item => eval(mi_evaluados))) //eslint-disable-line
		}else{
			var mi_evaluado = window._my_indicadores.filter(p => p.nombre===queson)[0].indicadores[0].key
			mi_evaluado = `item.${mi_evaluado}`
			cantidad_repetido =  Math.max(...arrayIndicadores.map(item => eval(mi_evaluado))) //eslint-disable-line
		}
	}else if(queson===mercadoTipo){
		let ids = arrayMercados.map(user => user.municipio)
	  let cantina = ids.sort( // ordena de dos en dos
	    (id1, id2) => // primer id a comparar, segundo id a comparar
	    ids.filter(valor => valor === id1).length // veces que aparece id1
	    -
	    ids.filter(valor => valor === id2).length //veces que aparecer id2
	  ).pop() // saca al primero que halles que tenga mas apariciones
		cantidad_repetido = arrayMercados.filter(p => p.municipio===cantina).length
	}
	//const [queson, setQueson] = useState("");

	let dividir
	let div2
	let div2a
	let div3
	let div3a
	let div4
	let div4a
	let div5
	let div5a
	if(cantidad_repetido>5){
		dividir=Math.ceil(cantidad_repetido/5);
		div2=dividir+1;
		div2a=dividir+dividir;
		div3=div2a+1;
		div3a=div2a+dividir;
		div4=div3a+1;
		div4a=div3a+dividir;
		div5=div4a+1;
		div5a=div4a+dividir;
	}
	//Fin para mapear color



	//show_circulo
	const [quitaClic, setQuitaClic] = useState(false);
	const [radio, setRadio] = useState("");
	const [circulo, setCirculo] = useState([]);
	const [circuloInfo, setCirculoInfo] = useState(null);
	const [markerNew, setMarkerNew] = useState([])
	const onLoad = (e) => {
	  if(e.type==='marker'){

		}else if(e.type==='polygon'){
			let polygonCoordsArray = [];
			let coords  = e.overlay.getPath().getArray();
			//console.log(path)
			for (let i = 0; i < coords.length; i++) {
				// console.log(coords[i].lat() + "," + coords[i].lng());
				polygonCoordsArray.push(
					coords[i].lat() + "," + coords[i].lng()
				);
			}
			console.log(polygonCoordsArray);
		}else if(e.type==='polyline'){

		}else if(e.type==='circle'){
			let c_radius = e.overlay.getRadius();
			let c_center = e.overlay.getCenter();

			let latitud = e.overlay.getCenter().lat();
			let longitud = e.overlay.getCenter().lng();
			console.log(c_radius)
			console.log(c_center)
			console.log(latitud)
			console.log(longitud)
			/*
			function checkCircleInMarker(markerPosition, circlePosition, radius) {
			  let km = radius/1000;
			  let kx = Math.cos(Math.PI * circlePosition.lat / 180) * 111;
			  let dx = Math.abs(circlePosition.lng - markerPosition.lng) * kx;
			  let dy = Math.abs(circlePosition.lat - markerPosition.lat) * 111;
			  return Math.sqrt(dx * dx + dy * dy) <= km;
			}
			*/
			//var stadium = cener;
		  //var avm = new window.google.maps.LatLng(41.06320196648882, 28.80718122666873);
		  //var radius=2500;
			/*
		  c_center = {lat: e.overlay.getCenter().lat(), lng: e.overlay.getCenter().lng()}

			let check = checkCircleInMarker(center, c_center, c_radius);
		  if(check){
		  	alert("In the region");
		  }else{
		  	alert("Out the region");
		  }
		  */
			//console.log(center.lat)

		}else if(e.type==='rectangle'){

		}
		//alerta('success','Debes seleccionar un telefonista'+e.type);
	}


	const funcionCirculo = (e) => {
		if(quitaClic===true){
			//console.log(e.latLng.toJSON())
			setActiveMarker(`circulo_radio`)
			setCoordenadasMarker(e.latLng.toJSON())
		}
	}
	const funcionPintarCirculo = () => {
		if(radio){
			//window.google.maps.LatLng(41.07444895969522, 28.765437643877718);
			//let meter = 2500;
			setActiveMarker(null)
			setQuitaClic(false)

			let arreglo_circulo = circulo
			let valores_enviar = {lat: coordenadasMarker.lat, lng: coordenadasMarker.lng, radius: radio*1000}
			arreglo_circulo.push(valores_enviar)
			setCirculo(arreglo_circulo)
		}else{
			alerta('error', idioma ? 'Debes indicar los kilómetros' : 'You must indicate the kilometers')
		}
	}
	const [circuloEsc, setCirculoEsc] = useState([]);
	const [circuloDenue, setCirculoDenue] = useState([]);
	const [circuloMercados, setCirculoMercados] = useState([]);
	//Fin show_circulo

  //Gráficas
  const [grafica_1, setgrafica_1] = useState([]);
  const [grafica_2, setgrafica_2] = useState([]);
  const funcionGraficas = (tipo) => {
  	if(modal===false){
  		setBuscar(tipo)
  	}
  	let arreglo_grafica_1 = [];
  	let arreglo_grafica_2 = [];
  	if(tipo==='show_escuelas'){
  		arreglo_grafica_1.push(["Municipio", "Total"])
	  	unique_municipio.map(item => 
        arreglo_grafica_1.push([item, arrayEsc.filter(p => p.municipio===item).length])
      )

	  	arreglo_grafica_2.push(["Carrera", "Total"])
	  	let miscarreras= []
	  	if(carreraEscuelas.length>0){
  			arrayEsc.map(item => 
  				carreraEscuelas.map(dos =>
  					item.es_carreras.filter(p => p.es_carrera===dos).map(car =>
							miscarreras.push(car.es_carrera)
						)
					)
				)
	  	}else{
				arrayEsc.map(item => 
					item.es_carreras.map(car =>
						miscarreras.push(car.es_carrera)
					)
				)
	  	}
			const las_unicas = [...new Set(miscarreras.map(item => item))];
			las_unicas.sort(function (a, b) {
			    return a[0].localeCompare(b[0]);
			});
      las_unicas.map(item => 
        arreglo_grafica_2.push([item, miscarreras.filter(p => p===item).length])
      )
	  }else if(tipo==='show_comercios'){
	  	arreglo_grafica_1.push(["Municipio", "Total"])
	  	arrayMunicipiosDenue.map(item =>
	  		arreglo_grafica_1.push([item.municipio, item.total])
  		)
	  }else if(tipo==='show_laboral'){
	  	arreglo_grafica_1.push(["Municipio", "Total"])
	  	arrayLaboral.map(item =>
	  		arreglo_grafica_1.push([item._id, item.total])
  		)
	  }else if(tipo==='show_mercados'){
	  	arreglo_grafica_1.push(["Municipio", "Total"])
	  	unique_municipio_mercados.map(item => 
        arreglo_grafica_1.push([item, arrayMercados.filter(p => p.municipio===item).length])
      )
	  }else if(tipo==='show_indicadores'){
	  	arreglo_grafica_1.push(["Municipio", "Total"])

	  	if(indica.length>0){
  			arreglo_grafica_2.push(["Indicador", "Cantidad"])
  			indica.map((invar, i) => { //eslint-disable-line
  				const mi_sus = `item.${invar.key}`
  				let misus = 0
  				arrayIndicadores.map(item => { //eslint-disable-line
  					misus = misus + eval(mi_sus) //eslint-disable-line
  				})
					arreglo_grafica_2.push([invar.label, misus])
				})
	  	}

	  	arrayIndicadores.map(item => { //eslint-disable-line
	  		let cantidad = 0
				if(indica.length>0){
					var mi_evaluados = ""
					indica.map((invar, i) => { //eslint-disable-line
						var sumada = ""
						if(i>0){
							sumada = "+"
						}
						mi_evaluados += `${sumada}item.${invar.key}`
					})
					cantidad = eval(mi_evaluados) //eslint-disable-line
				}else{
					var mi_evaluado = window._my_indicadores.filter(p => p.nombre===queson)[0].indicadores[0].key
					mi_evaluado = `item.${mi_evaluado}`
					cantidad = eval(mi_evaluado) //eslint-disable-line
				}
        arreglo_grafica_1.push([item.municipio, cantidad])
      })
	  }
	  setgrafica_1(arreglo_grafica_1)
	  setgrafica_2(arreglo_grafica_2)
	  setGraficasModal(true)
  }
  //Fin gráficas


	//Selects
	const [denue, setDenue] = useState([]);
	const selectForm = () => {
    window.H5_loading.show();
    var params = new URLSearchParams();
    params.append('cual', 'denue');
    Axios.post(`${window.ws}wsSelect.php`, params)
      .then(response => {
        if(response.data.elError===1){
          setDenue(response.data.lista_array)
        }else{
          console.log(response.data.mensaje)
        }
      })
      .catch(error => {
        console.log(error)
      })
      .then(function() {
        window.H5_loading.hide();
      })
	}
/*
	const selectImsss = () => {
    window.H5_loading.show();
    var params = new URLSearchParams();
    params.append('cual', 'denue');
    Axios.post(`${window.ruta2}w_fer`, params)
      .then(response => {
        if(response.data.elError===1){
          alerta('success', 'si')
          setMongo(response.data.basemongo)
        }else{
          alerta('error', 'no')
          //console.log(response.data.mensaje)
        }
        /*
        setMongo(response.data.basemongo)
        alerta('success', 'si')
        console.log(response.data.length)
        console.log(response.data)
        *//*
      })
      .catch(error => {
      	alerta('error', 'nel')
        console.log(error)
      })
      .then(function() {
        window.H5_loading.hide();
      })
	}*/

	const [division, setDivision] = useState([]);
	const listaDivision = () => {
		window.H5_loading.show();
		Axios.get(window.ruta2+'w_division')
		.then(response => {
			setDivision(response.data)
		})
    .catch(error => console.error(error))
    .then(function() {
      window.H5_loading.hide();
    })
	}
	//Fin selects

	const cerrarModal = () => {
		setModal(false)
  	setInformacion("")
  	setFotografia("")
  	setBuscar("")
  	setCirculoInfo(null)
	}

	const funcionLimpiar = () => {
		//setArrayEsc()
		setActiveMarker(null)
		setArrayIndicadores([])
		setArrayDenue([])
		setArrayMunicipiosDenue([])
		setMayorDenue(0)
		setArrayLaboral([])
		setArrayMercados([])
		setArrayEsc([])
		setQueson("")
		setLimpiar(0)
		setMiUbicacion("")
		valoresDraw.forEach(shape => shape.setMap(null))
		setDrawSuma(0)
		setValoresDraw([])
		setCirculo([])
		setMarkerNew([])
		window._my_capas.filter(p => p.tipo!=='buscar').map(micapa => { //eslint-disable-line
			let elEval = `set${micapa.id}(${micapa.check})`
			eval(elEval) //eslint-disable-line
		})
	}
	const [limpiar, setLimpiar] = useState(0);

	const [miUbicacion, setMiUbicacion] = useState("");
	const funcionUbicacion = () => {
		if(miUbicacion){
			setMiUbicacion("")
			setActiveMarker(null)
		}else{
			//if(!"geolocation" in navigator){
			if((!"geolocation") in navigator){
				return alerta('error', "Tu navegador no soporta el acceso a la ubicación. Intenta con otro");
			}

			const onUbicacionConcedida = ubicacion => {
				//console.log("Tengo la ubicación: ", ubicacion);
				setMiUbicacion(ubicacion.coords)
				//console.log(ubicacion.coords)
				setCentro({lat: ubicacion.coords.latitude, lng: ubicacion.coords.longitude})
			}
		  
			const onErrorDeUbicacion = err => {
				console.log("Error obteniendo ubicación: ", err);
			}

			const opcionesDeSolicitud = {
				enableHighAccuracy: true, // Alta precisión
				maximumAge: 0, // No queremos caché
				timeout: 5000 // Esperar solo 5 segundos
			};
			// Solicitar
			navigator.geolocation.getCurrentPosition(onUbicacionConcedida, onErrorDeUbicacion, opcionesDeSolicitud);
		}
	}

	const [visitantes, setVisitantes] = useState('');
	const visitantesContador = () => {
		window.H5_loading.show();

		Axios.get('http://mapa.investhidalgo.mx/ws/contador_mapa.php')
		.then(response => {
			setVisitantes(response.data)
		})
    .catch(error => console.error(error))
    .then(function() {
      window.H5_loading.hide();
    })
	}

	const {isLoaded/*, loadError*/} = useLoadScript({
    googleMapsApiKey: "AIzaSyAKai9CPm8GB-SaANwPNy5NAW1KQQzNKxI",
    //googleMapsApiKey: "AIzaSyD-cC86-F49KpmsmH_3KRCOsYpvasZYh1Y",
    libraries,
  })

  //TRADUCTOR
  const [traductor, setTraductor] = useState('none');
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false
      },
      "google_translate_element"
    );
  };
  //FIN TRADUCTOR

	const [idioma, setIdioma] = useState("es");
	useEffect(() => {
		selectForm();

		if(window.location.href==='http://148.223.224.75/mapainvesth/index.html?idioma=es' || window.location.href==='http://localhost:3000/index.html?idioma=es' || window.location.href==='http://mapa.investhidalgo.mx/index.html?idioma=es'){
      setIdioma("es")
    }

		//selectImsss();
		listaDivision();

		visitantesContador();
		window.H5_loading.show();
    setTimeout(() => {
      window.H5_loading.hide();
    }, 1000)

    //TRADUCTOR
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
    //FIN TRADUCTOR
	}, []);

	let titulo_municipio = ""
	let titulo_pea = 0
	let tabla_sub_total = 0
	let tabla_total = 0
	let tabla_sub_total_pea = 0
	let tabla_total_pea = 0

	return (
		<div>
			<a href="https://investhidalgo.mx/" className="logo_investh">
				<img src="https://investhidalgo.mx/img/logo_investh.svg" alt="Invest Hidalgo" title="Invest Hidalgo"/>
			</a>
			<div className="icono_menu" onClick={() => setMenu(true)}>
				<img src="img/menu.svg" title={idioma ? "Menú" : "Menu"} alt="Menu"/>
			</div>

			{/*
				mongo.length>0 ?
					mongo.map(item =>
						<div>lol</div>
					)
				:
					null
			*/}
			<div className="cerrar_menu" style={{display: menu===true ? 'block' : 'none'}} onClick={() => setMenu(false)}></div>
			<div className="flex_mapa">
				<div className="menu" id={menu===true ? 'ver_menu' : 'quitar_menu'}>
					<div className="logo">
						<img src="img/logo.svg" title={idioma ? "Mapa Económico" : "Economic Map"} alt="Mapa Económico" className="grises" onClick={() => window.location.reload()}/>
						{idioma ? <div>Mapa<br/>Económico</div> : <div>Economic<br/>Map</div>}
					</div>
					<div className="idiomas">
						<div className="idiomasx" onClick={() => {
							setIdioma("")
							setTraductor("none")
						}}>English</div>
            <div className="idiomasx" onClick={() => {
            	setIdioma("es")
            	setTraductor("none")
            }}>Español</div>
            <div className="idiomasx" onClick={() => setTraductor("block")}>Other</div>
					</div>
					<div className="traductor" style={{display: traductor}}><div id="google_translate_element"/></div>
					{
						window._my_menu.length>0 ?
							<Accordion alwaysOpen>
								{
									window._my_menu.map((item, i) =>
										<Accordion.Item eventKey={i}>
							        <Accordion.Header className="acordeon_titulo">{idioma ? item.title : item.titleIn}</Accordion.Header>
							        <Accordion.Body>
							          {
													item.title==='Indicadores' ?
														<>
															{
																window._my_capas.filter(p => p.catalogo==='Indicadores').length>0 ?
																	window._my_capas.filter(p => p.catalogo==='Indicadores').map((micapa, n) => 
																		<div className="acordeon" key={n} onClick={() => {
													          	setModal(true)
																			setBuscar(micapa.id)
													          }}>
													          	<div className="acordeon_texto">
													          		{
													          			micapa.imagen ?
													          				<img src={micapa.imagen} title={idioma ? micapa.nombre : micapa.nombreIn} alt={micapa.nombre}/>
												          				: micapa.circulo ?
												          					<div className="circulo_color" style={{color:micapa.color}}>●</div>
													          			: micapa.color ?
												          					<div className="linea_color" style={{color:micapa.color}}>■■</div>
													          			:
													          				null
													          		}
													          		<div>{idioma ? micapa.nombre : micapa.nombreIn}</div>
												          		</div>
												          		<img src="img/iconos/buscar.svg" title={idioma ? "Buscar" : "Search"} alt="Buscar" className="grises"/>
													          </div>
																	)
																:
																	null
															}
															{
																window._my_indicadores.map((indi, n) =>
																	<div
																		className="acordeon"
																		key={n}
																		onClick={() => {
																			setModal(true)
																			setBuscar('show_indicadores')
																			setIndicadores(indi.nombre)
																			if(queson!==indi.nombre){
																				setIndicadoresVariable([])
																			}
																		}}
																	>
																		<div className="acordeon_texto">
																			<img src="img/iconos/municipio-02.svg" title={idioma ? "Indicadores" : "Indicators"} alt="Indicadores"/>
												          		<div>{idioma ? indi.nombre : indi.nombreIn}</div>
											          		</div>
											          		<img src="img/iconos/buscar.svg" title={idioma ? "Buscar" : "Search"} alt="Buscar" className="grises"/>
																	</div>
																)
															}
														</>
													:
														window._my_capas.filter(p => p.catalogo===item.title).length>0 ?
															window._my_capas.filter(p => p.catalogo===item.title).map((micapa, n) => {
																let elEval
																let elSetEval
																if(micapa.tipo!=='buscar'){
																	elEval = eval(micapa.id) //eslint-disable-line
																	let cambia = 1
																	if(elEval>0){
																		cambia = 0
																	}
																	elSetEval = `set${micapa.id}(${cambia})`
																}
																return(
																	<div>
																		{micapa.arriba ? <div className="arriba">{idioma ? micapa.arriba : micapa.arribaIn}</div>: null}
																		<div className="acordeon" key={n} onClick={() => {
													          	if(micapa.tipo==='buscar'){
													          		setModal(true)
																				setBuscar(micapa.id)
													          	}else{
													          		if(micapa.check===0){
														          		if(elEval>0){
													          				setLimpiar(limpiar-1)
													          			}else{
													          				setLimpiar(limpiar+1)
													          			}
												          			}
													          		
													          		eval(elSetEval) //eslint-disable-line

													          		if(elEval===0){
														          		if(micapa.centro){
														          			/*
														          			if(micapa.centro===centro){
														          				//setZoom(micapa.zoom+0.001)
														          				let lati = micapa.centro.lat
														          				const ultima_lati = lati.slice(-1)
														          				alert(ultima_lati)
														          				let cordena = {lat: lati, lng: micapa.centro.lng}
														          				setCentro(micapa.centro)
														          			}else{
														          				setCentro(micapa.centro)
														          			}
														          			*/
														          			setCentro(micapa.centro)
														          		}else{
														          			if(centro.lat===center.lat){
														          				setCentro(centerDos)
														          			}else{
														          				setCentro(center)
														          			}
														          		}

														          		if(micapa.zoom){
														          			if(micapa.zoom===zoom){
														          				setZoom(micapa.zoom+0.01)
														          			}else{
														          				setZoom(micapa.zoom)
														          			}
														          		}else{
														          			if(zoom===mi_zoom){
														          				setZoom(mi_zoomDos)
														          			}else{
														          				setZoom(mi_zoom)
														          			}
														          		}
														          	}
													          	}
													          }}>
													          	<div className="acordeon_texto">
													          		{
													          			micapa.imagen ?
													          				<img src={micapa.imagen} title={idioma ? micapa.nombre : micapa.nombreIn} alt={micapa.nombre}/>
												          				: micapa.circulo ?
												          					<div className="circulo_color" style={{color:micapa.color}}>●</div>
													          			: micapa.color ?
												          					<div className="linea_color" style={{color:micapa.color}}>■■</div>
													          			:
													          				null
													          		}
													          		<div>{idioma ? micapa.nombre : micapa.nombreIn}</div>
												          		</div>
												          		{
												          			micapa.tipo==='buscar' ?
												          				<img src="img/iconos/buscar.svg" title={idioma ? "Buscar" : "Search"} alt="Buscar" className="grises"/>
												          			:
													          			<div className="acordeon_check" id={elEval>0 ? 'acordeon_check_color' : ''}>✓</div>
													          	}
													          </div>
												          </div>
											          )
															})
														:
															null
													}
													{/*
								          <div className="acordeon" onClick={() => {
								          	if(!Capa_urbana){
												  		setCapa_urbana(1)
												  	}else{
												  		setCapa_urbana(0)
												  	}
								          }}>
								          	<div className="acordeon_texto">
								          		<img src="img/iconos/urbano.svg" title="" alt=""/>
								          		<div>Zonas urbanas</div>
							          		</div>
								          	<div className="acordeon_check">✓</div>
								          </div>
								        */}
							        </Accordion.Body>
							      </Accordion.Item>
									)
								}
							</Accordion>
						:
							null
					}
					{/*
					{cantidad_repetido}el mas repe: {window.buscaRepetido(arrayEsc, 'es_municipio')}, cuantas veces: {arrayEsc.filter(p => p.es_municipio===window.buscaRepetido(arrayEsc, 'es_municipio')).length}, las escuelas: {arrayEsc.length},el divide: {dividir}<br/>
					*/}
					{/*
						unique_municipio.length>0 ?
							unique_municipio.map(item => {
								let cantidad = unificarMunicipio.filter(p => p.municipio===item).length
										let colormun = "#bc945a"
										if(cantidad<=dividir){colormun="GREEN";}else 
										if(cantidad<=div2a && cantidad>=div2){colormun="YELLOW";}else 
										if(cantidad<=div3a && cantidad>=div3){colormun="BLUE";}else 
										if(cantidad<=div4a && cantidad>=div4){colormun="ORANGE";}else 
										if(cantidad>=div5){colormun="RED";}
								return(
									<div>{item} {cantidad} , colormun {colormun}, {window._arreglo_coordenadas.filter(p => p.municipio.toUpperCase()===item || p.municipio===item).length}</div>
								)
							})
						:
							null
					}*/}
					{visitantes ? <div className="visitas">{idioma ? "Visitas" : "Visits"} {visitantes}</div> : null}
				</div>
				<div className="mapa">
					{/*}
					<LoadScript
						googleMapsApiKey="AIzaSyAKai9CPm8GB-SaANwPNy5NAW1KQQzNKxI"
						//googleMapsApiKey=""
						libraries={["drawing"]}
						loadingElement={<div className="loading_map">{idioma ? "Cargando" : "Loading"}...</div>}
					>*/}
						{ 
							isLoaded ? 
								<div>
									{
										window.location.href.split('/')[2]==='localhost:3000' || window.location.href.split('/')[0]==='https:' ?
											<div className="mi_ubicacion" onClick={() => funcionUbicacion()}>
												<img src="img/miubicacion.svg" title={idioma ? "Mi ubicación" : "My location"} alt="Ubicación"/>
											</div>
										:
											null
									}
									<div className="el_radio" id={quitaClic===true ? "color_radio" : ""} onClick={() => setQuitaClic(!quitaClic)}>
										<img src="img/radio.svg" title={idioma ? "Radio" : "Radius"} alt="Radio"/>
										{idioma ? "Radio" : "Radius"}
									</div>
									<div className="redes_sociales">
										{
			                window._my_redes.map((item, i) => 
			                  <>
			                  	<a href={item.link} target="_new" className="de_flex_redes grises" key={i}><img src={item.imagen} alt={item.nombre} title={item.nombre}/></a>
			                		<div id="cl"></div>
			                	</>
			                )
			              }
									</div>
									{
						      	cantidad_repetido>5 ?
							      	<div className="de_barrita" id={div5a.toString().length>6 ? 'fuentezita' : 'fuentezitaDos'}>
												<div className="barrita barra_white" title={`0 ${queson}`}>0</div>
												<div className="barrita barra_green" title={`1 a ${window.formatoMexico(dividir)} ${queson}`}>1 &nbsp;{idioma ? "a" : "to"}&nbsp; {window.formatoMexico(dividir)}</div>
												<div className="barrita barra_yellow" title={`${window.formatoMexico(div2)} a ${window.formatoMexico(div2a)} ${queson}`}>{window.formatoMexico(div2)} &nbsp;{idioma ? "a" : "to"}&nbsp; {window.formatoMexico(div2a)}</div>
												<div className="barrita barra_blue" title={`${window.formatoMexico(div3)} a ${window.formatoMexico(div3a)} ${queson}`}>{window.formatoMexico(div3)} &nbsp;{idioma ? "a" : "to"}&nbsp; {window.formatoMexico(div3a)}</div>
												<div className="barrita barra_orange" title={`${window.formatoMexico(div4)} a ${window.formatoMexico(div4a)} ${queson}`}>{window.formatoMexico(div4)} &nbsp;{idioma ? "a" : "to"}&nbsp; {window.formatoMexico(div4a)}</div>
												<div className="barrita barra_red" title={`${window.formatoMexico(div5)} a ${window.formatoMexico(div5a)} ${queson}`}>{window.formatoMexico(div5)} &nbsp;{idioma ? "a" : "to"}&nbsp; {window.formatoMexico(div5a)}</div>
											</div>
										:
											null
						      }
						      {
						      	arrayEsc.length>0 || arrayDenue.length>0 || arrayMercados.length>0 || arrayIndicadores.length>0 || arrayLaboral.length>0 ?
								      <>
									      <div className="limpiar" id="limpiar_izq" onClick={() => funcionLimpiar()}>
									      	{idioma ? "Limpiar" : "Limpiar"}
									      </div>
									      <div className="iconos_flotan">
									      	{
									      		arrayIndicadores.length>0 ?
									    				<etiqueta className="iconos_iconitos">
										      			<div className="img_flotar">
											      			<img src="img/iconos/municipio-02.svg" title={idioma ? "Indicadores" : "Indicators"} alt="Indicadores" onClick={() => {
											      				setModal(true)
																		setBuscar('show_indicadores')
																		setIndicadores(indicadorPrincipal)
																	}}/>
																</div>
										      			{
										      				arrayEsc.length<2001 ?
												      			<CSVLink
																			headers={window._my_indicadores.filter(p => p.nombre===indicadorPrincipal)[0].indicadores}
																			data={arrayIndicadores}
																			target="_blank"
																			//separator={"|"}
																			filename={"indicadores.csv"}
																			className="botones_iconitos"
																		>
																			<img src="img/ico_excel.svg" title={idioma ? "Descargar CSV" : "Download CSV"} alt="CSV"/>
																		</CSVLink>
																	:
																		null
																}
																<div className="botones_iconitos" onClick={() => {
																	funcionGraficas('show_indicadores')
																}}>
																	<img src="img/ico_grafica.svg" title={idioma ? "Ver gráficas" : "See graphs"} alt="Gráficas"/>
																</div>
																<div className="botones_iconitos" id={window._my_indicadores.filter(p => p.nombre===queson).length>0 ? 'marcado' : ''} onClick={() => {
																	if(window._my_indicadores.filter(p => p.nombre===queson).length>0){
																		setQueson("")
																	}else{
																		setQueson(indicadorPrincipal)
																		functionZoom()
																	}
																}}>
																	<img src="img/ico_mapa.svg" title={idioma ? "Ver concentración por municipio" : "See concentration by municipality"} alt="Concentración"/>
																</div>
															</etiqueta>	      			
									      		:
									      			null
									      	}
									      	<div id="cl"></div>
									      	{
									      		arrayEsc.length>0 ?
									    				<etiqueta className="iconos_iconitos">
										      			<div className="img_flotar">
											      			<img src="img/iconos/escuelas.svg" title={idioma ? "Planteles educación media superior" : "Universities and high schools"} alt="Planteles educación media superior" onClick={() => {
											      				setModal(true)
																		setBuscar('show_escuelas')
																	}}/>
																</div>
										      			{
										      				arrayEsc.length<2001 ?
												      			<CSVLink
																			headers={window._escuelas_header}
																			data={arrayEsc}
																			target="_blank"
																			//separator={"|"}
																			filename={"educacion_superior.csv"}
																			className="botones_iconitos"
																		>
																			<img src="img/ico_excel.svg" title={idioma ? "Descargar CSV" : "Download CSV"} alt="CSV"/>
																		</CSVLink>
																	:
																		null
																}
																<div className="botones_iconitos" onClick={() => {
																	funcionGraficas('show_escuelas')
																}}>
																	<img src="img/ico_grafica.svg" title={idioma ? "Ver gráficas" : "See graphs"} alt="Gráficas"/>
																</div>
																<div className="botones_iconitos" id={queson==='escuelas' ? 'marcado' : ''} onClick={() => {
																	if(queson==='escuelas'){
																		setQueson("")
																	}else{
																		setQueson("escuelas")
																		functionZoom()
																	}
																}}>
																	<img src="img/ico_mapa.svg" title={idioma ? "Ver concentración por municipio" : "See concentration by municipality"} alt="Concentración"/>
																</div>
															</etiqueta>	      			
									      		:
									      			null
									      	}
									      	<div id="cl"></div>
									      	{
									      		arrayMercados.length>0 ?
									    				<etiqueta className="iconos_iconitos">
										      			<div className="img_flotar">
											      			<img src="img/icos/mercados.png" title={idioma ? "Mercados y centrales" : "Markets and supply centers"} alt="Mercados y centrales" onClick={() => {
											      				setModal(true)
																		setBuscar('show_mercados')
																	}}/>
																</div>
										      			{
										      				arrayMercados.length<2001 ?
												      			<CSVLink
																			headers={window._mercados_header}
																			data={arrayMercados}
																			target="_blank"
																			//separator={"|"}
																			filename={"mercados-centrales.csv"}
																			className="botones_iconitos"
																		>
																			<img src="img/ico_excel.svg" title={idioma ? "Descargar CSV" : "Download CSV"} alt="CSV"/>
																		</CSVLink>
																	:
																		null
																}
																<div className="botones_iconitos" onClick={() => {
																	funcionGraficas('show_mercados')
																}}>
																	<img src="img/ico_grafica.svg" title={idioma ? "Ver gráficas" : "See graphs"} alt="Gráficas"/>
																</div>
																<div className="botones_iconitos" id={queson===mercadoTipo ? 'marcado' : ''} onClick={() => {
																	if(queson===mercadoTipo){
																		setQueson("")
																	}else{
																		setQueson(mercadoTipo)
																		functionZoom()
																	}
																}}>
																	<img src="img/ico_mapa.svg" title={idioma ? "Ver concentración por municipio" : "See concentration by municipality"} alt="Concentración"/>
																</div>
															</etiqueta>	      			
									      		:
									      			null
									      	}
									      	<div id="cl"></div>
									      	{
									      		arrayDenue.length>0 ?
									      			<div className="iconos_iconitos">
										      			<div className="img_flotar">
											      			<img src="img/icos/establecimientos.png" title={idioma ? "Empresas y establecimientos" : "Companies and establishments"} alt="Empresas y establecimientos" onClick={() => {
											      				setModal(true)
																		setBuscar('show_comercios')
																	}}/>
																</div>
										      			{
										      				arrayDenue.length<2001 ?
												      			<CSVLink
																			headers={window._denue_header}
																			data={arrayDenue}
																			target="_blank"
																			//separator={"|"}
																			filename={"empresas_denue.csv"}
																			className="botones_iconitos"
																		>
																			<img src="img/ico_excel.svg" title={idioma ? "Descargar CSV" : "Download CSV"} alt="CSV"/>
																		</CSVLink>
																	:
																		null
																}
																<div className="botones_iconitos" onClick={() => {
																	funcionGraficas('show_comercios')
																}}>
																	<img src="img/ico_grafica.svg" title={idioma ? "Ver gráficas" : "See graphs"} alt="Gráficas"/>
																</div>
																<div className="botones_iconitos" id={queson==='establecimientos' ? 'marcado' : ''} onClick={() => {
																	if(queson==='establecimientos'){
																		setQueson("")
																	}else{
																		setQueson("establecimientos")
																		functionZoom()
																	}
																}}>
																	<img src="img/ico_mapa.svg" title={idioma ? "Ver concentración por municipio" : "See concentration by municipality"} alt="Concentración"/>
																</div>
															</div>
									      		:
									      			null
									      	}
									      	<div id="cl"></div>
									      	{
									      		arrayLaboral.length>0 ?
									      			<div className="iconos_iconitos">
										      			<div className="img_flotar">
											      			<img src="img/iconos/municipio-02.svg" title={idioma ? "Laboral" : "Labor"} alt="Laboral" onClick={() => {
											      				setModal(true)
																		setBuscar('show_laboral')
																	}}/>
																</div>
										      			{
										      				arrayLaboral.length<2001 ?
												      			<CSVLink
																			headers={window._laboral_header}
																			data={arrayLaboral}
																			target="_blank"
																			//separator={"|"}
																			filename={"laboral.csv"}
																			className="botones_iconitos"
																		>
																			<img src="img/ico_excel.svg" title={idioma ? "Descargar CSV" : "Download CSV"} alt="CSV"/>
																		</CSVLink>
																	:
																		null
																}
																<div className="botones_iconitos" onClick={() => {
																	funcionGraficas('show_laboral')
																}}>
																	<img src="img/ico_grafica.svg" title={idioma ? "Ver gráficas" : "See graphs"} alt="Gráficas"/>
																</div>
																<div className="botones_iconitos" id={queson==='trabajadores' ? 'marcado' : ''} onClick={() => {
																	if(queson==='trabajadores'){
																		setQueson("")
																	}else{
																		setQueson("trabajadores")
																		functionZoom()
																	}
																}}>
																	<img src="img/ico_mapa.svg" title={idioma ? "Ver concentración por municipio" : "See concentration by municipality"} alt="Concentración"/>
																</div>
															</div>
									      		:
									      			null
									      	}
									      </div>
								      </>
							      : limpiar>0 || miUbicacion || drawSuma>0 ?
							      		<div className="limpiar" id="limpiar_dere" onClick={() => funcionLimpiar()} title={limpiar}>
									      	{idioma ? "Limpiar" : "Clean"}
									      </div>
							      :
							      	null
						      }

									<GoogleMap
										mapContainerStyle={window.containerStyle}
										center={centro}
										zoom={zoom}
										options={{
											styles: window.styleMap,
											mapTypeId: "roadmap",
											mapTypeControl: navegador>0 || window.screen.width<501 ? false : true,
											mapTypeControlOptions: {position: 0},
											zoomControlOptions: { position: 5 },
											streetViewControlOptions: {position: 5},
											fullscreenControlOptions: {position: 5},
											draggableCursor: quitaClic===false ? 'default' : 'pointer',
										}}

										onClick={(e) => {
											//setActiveMarker(`hacer_circulo`)
											//setCoordenadasMarker(e.latLng.toJSON())
											funcionCirculo(e)
										}}
									>

										{/*
								    <DrawingManager
								      options={{
												drawingControlOptions: {position: 10},
											}}
											drawingControl="true"
											drawingMode="marker"
										/>*/}

									  {
									  	activeMarker==='circulo_radio' ?
									  		<InfoWindow
										      onCloseClick={() => setActiveMarker(null)} //eslint-disable-line
										      position={coordenadasMarker}
										    >
										      <div className="info_window">
														<Form onSubmit={(e) => e.preventDefault()}>
															<Form.Group>
												        <Form.Label>{idioma ? "Radio en kilometros" : "Radius in kilometers"}:</Form.Label>
												        <div className="flex_form">
												        	<Form.Control type="number" placeholder="0" value={radio} onChange={(event) => setRadio(event.target.value)}/>
												      		<Button variant="success" onClick={() => funcionPintarCirculo()}>
												      			>
												      		</Button>
												      	</div>
												      </Form.Group>
											      </Form>
										      </div>
										    </InfoWindow>
									    :
									    	null
									  }

									  {
									  	circulo.length>0 ?
									  		circulo.map((item, i) =>
									  			<Circle
									  				key={i}
											      center={{lat: item.lat, lng: item.lng}}
											      options={{
														  strokeColor: '#dd4f12',
														  strokeOpacity: 0.8,
														  strokeWeight: 2,
														  fillColor: '#dd4f12',
														  fillOpacity: 0.35,
														  clickable: !quitaClic,
														  draggable: true,
														  editable: false,
														  visible: true,
														  radius: item.radius,
														  zIndex: 3
														}}
														onDragEnd={(center, radius) => {
															//alert('lol')
															const { latLng } = center;
					                    const lat = latLng.lat();
					                    const lng = latLng.lng();
					                    //console.log(lat)
					                    //console.log(item.lat)

															let nuevo_arreglo = circulo.filter((p, n) => n!==i)
															let valores_enviar = {lat: lat, lng: lng, radius: item.radius}
															nuevo_arreglo.push(valores_enviar)
															setCirculo(nuevo_arreglo)

														}}
														onClick={() => {
															window.H5_loading.show();
														  /*
															let check = checkCircleInMarker(center, item);
														  if(check){
														  	alert("In the region");
														  }else{
														  	alert("Out the region");
														  }
														  */
														  if(arrayEsc.length>0){
														  	setCirculoEsc(arrayEsc.filter(p => checkCircleInMarker({lat: p.es_latitud, lng: p.es_longitud}, item)))
														  }else{
														  	setCirculoEsc([])
														  }
														  if(arrayDenue.length>0){
														  	setCirculoDenue(arrayDenue.filter(p => checkCircleInMarker({lat: parseFloat(p.latitud), lng: parseFloat(p.longitud)}, item)))
														  }else{
														  	setCirculoDenue([])
														  }
														  if(arrayMercados.length>0){
														  	setCirculoMercados(arrayMercados.filter(p => checkCircleInMarker({lat: p.latitud, lng: p.longitud}, item)))
														  }else{
														  	setCirculoMercados([])
														  }

														  setCirculoInfo(item)
														  setModal(true)
														  setBuscar('show_circulo')
														  window.H5_loading.hide();
														}}
														onRightClick={() => {
															//alert('lol')
															let nuevo_arreglo = circulo.filter((p, n) => n!==i)
															setCirculo(nuevo_arreglo)
														}}
											    >
											    </Circle>
								  			)
									  	:
									  		null
									  }
									  {
									  	markerNew.length>0 ?
									  		markerNew.map((marca, i) =>
										  		<Marker
											      position={{lat: parseFloat(marca.latitud), lng: parseFloat(marca.longitud)}}
											      onClick={() => setActiveMarker(`show_comercios_${marca.id}`)}
											      icon={{
											      	url: "img/icos/establecimientos.png",
											      	scaledSize: new window.google.maps.Size(25,25)
											      }}
											      animation={2}
											      clickable={!quitaClic}
											      zIndex={10}
											    >
											    	{
												    	activeMarker===`show_comercios_${marca.id}` ?
													    	<InfoWindow
														      onCloseClick={() => setActiveMarker(null)} //eslint-disable-line
														    >
														      <div className="info_window">
														      	<div class="pa_padin">
													      			{idioma ? "Establecimiento" : "Establishment"}<br/>
													      			<h10>{marca.nom_estab}</h10>
												      			</div>
												      			<div class="modal_linea"></div>
												      			<table class="tabla_modal">
												      				<tr>
												      					<td>{idioma ? "Actividad económica" : "Economic activity"}:</td>
												      					<td>{marca.nombre_act}</td>
											      					</tr>
											      					<tr>
												      					<td>{idioma ? "Tamaño" : "Size"}:</td>
												      					<td>
												      						{
												      							marca.per_ocu==='251 y más personas' ?
												      								idioma ? 'Grande' : 'Big'
												      							: marca.per_ocu==='51 a 100 personas' || marca.per_ocu==='101 a 250 personas' ?
												      								idioma ? 'Mediana' : 'Medium'
												      							: marca.per_ocu==='11 a 30 personas' || marca.per_ocu==='31 a 50 personas' ?
												      								idioma ? 'Pequeña' : 'Small'
												      							:
												      								'Micro'
												      						}
											      						</td>
											      					</tr>
											      					<tr>
												      					<td>{idioma ? "Municipio" : "Municipality"}:</td>
												      					<td>{marca.municipio}</td>
											      					</tr>
										      						<tr>
												      					<td>{idioma ? "C.P" : "P.C"}:</td>
												      					<td>{marca.cod_postal}</td>
											      					</tr>
											      					{
											      						marca.localidad ?
											      							<tr>
													      						<td>{idioma ? "Localidad" : "Locality"}:</td>
													      						<td>{marca.localidad}</td>
												      						</tr>
											      						:
											      							null
										      						}
											      					{
											      						marca.telefono ?
											      							<tr>
													      						<td>{idioma ? "Teléfono" : "Telephone"}:</td>
													      						<td>{marca.telefono}</td>
												      						</tr>
											      						:
											      							null
										      						}
										      						{
											      						marca.correoelec ?
											      							<tr>
													      						<td>{idioma ? "Correo electrónico" : "Email"}:</td>
													      						<td>{marca.correoelec.toLowerCase()}</td>
												      						</tr>
											      						:
											      							null
										      						}
														        	{
											      						marca.www ?
											      							<tr>
													      						<td>{idioma ? "Sitio web" : "Website"}:</td>
													      						<td><a href={marca.www} target="new" class="link_modal">{marca.www.toLowerCase()}</a></td>
												      						</tr>
											      						:
											      							null
										      						}
									      						</table>
														      </div>
														    </InfoWindow>
													    :
													    	null
												    }
											    </Marker>
										    )
									    :
									    	null
									  }			  

							      {
							      	window.screen.width>788 ?
									      <DrawingManager
										      options={{
														drawingControlOptions: {position: 2},
														//polygonOptions: { editable: true },
														//polylineOptions: { editable: true },
														//circleOptions: { editable: true },
														//markerOptions: { editable: true },
														//rectangleOptions: { editable: true },

														/*
															markerOptions: {
													      icon: "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
													    },
													    circleOptions: {
													      fillColor: "#ffff00",
													      fillOpacity: 1,
													      strokeWeight: 5,
													      clickable: false,
													      editable: true,
													      zIndex: 1,
													    },
														*/
													}}
													drawingControl="true"
													//drawingMode={quitaClic===false ? null : null}
									      	//onCircleComplete={() => onLoad()}
									      	onOverlayComplete={(e) => { 
									      		//onLoad()
									      		let arreglo_push = valoresDraw
									      		arreglo_push.push(e.overlay)
									      		setValoresDraw(arreglo_push)
									      		setDrawSuma(drawSuma+1)
									      		
									      		//setDibujo(e.type)
									      		//console.log(e.overlay.position)

									      		//this.google.maps.event.addListener(overlayRef.current, 'dragend', onLoad);
									      		//window.google.maps.Size(25,25)
									      		window.google.maps.event.addListener(e.overlay, "click", () => {
									      			//var lat = event.latLng.lat();
													    //var lng = event.latLng.lng();
													    // populate yor box/field with lat, lng
													    //alert("Lat=" + lat + "; Lng=" + lng);
												      onLoad(e)
												    })

												    window.google.maps.event.addListener(e.overlay, "rightclick", () => {
									      			e.overlay.setMap(null)
															//setDrawSuma(drawSuma-1)
												    })
														
														/*
									      		console.log(e.type)
									      		if(e.type==='marker'){

									      		}else if(e.type==='polygon'){
									      			let polygonCoordsArray = [];
									      			let coords  = e.overlay.getPath().getArray();
									      			//console.log(path)
															for (let i = 0; i < coords.length; i++) {
																// console.log(coords[i].lat() + "," + coords[i].lng());
																polygonCoordsArray.push(
																	coords[i].lat() + "," + coords[i].lng()
																);
															}
															console.log(polygonCoordsArray);
									      		}else if(e.type==='polyline'){

									      		}else if(e.type==='circle'){
									      			let radius = e.overlay.getRadius();
									      			let center = e.overlay.getCenter();
									      			console.log(radius)
									      			console.log(center)
									      		}else if(e.type==='rectangle'){

									      		}
									      		*/
									      		//console.log(e.overlay)


														/*
									      		this.google.maps.event.addListener(e.type, "click", () => {
												      onLoad()
												    });*/


									      		/*
												    const shape = e.overlay;
												    shape.type = e.type;
												    google.maps.event.addListener(shape, "click", () => {
												      this.toggleSelection(shape);
												    });
												    this.toggleSelection(shape);
												    this.shapes.push(shape)
												    */
													}}
									      />
								      :
								      	null
							      }


							      {//INDICADORES
											arrayIndicadores.length>0 && window._my_indicadores.filter(p => p.nombre===queson).length>0 ?
												arrayIndicadores.map(item => { //eslint-disable-line
													let cantidad = 0
													if(indica.length>0){
														var mi_evaluados = ""
														indica.map((item, i) => { //eslint-disable-line
															var sumada = ""
															if(i>0){
																sumada = "+"
															}
															mi_evaluados += `${sumada}item.${item.key}`
														})
														cantidad = eval(mi_evaluados) //eslint-disable-line
													}else{
														var mi_evaluado = window._my_indicadores.filter(p => p.nombre===queson)[0].indicadores[0].key
														mi_evaluado = `item.${mi_evaluado}`
														cantidad = eval(mi_evaluado) //eslint-disable-line
													}

													if(cantidad>0){
														let el_municipio = item.municipio
														let la_coordenada = window._arreglo_coordenadas.filter(p => p.municipio===item.municipio)[0]

														let colormun = "#bc945a"
														if(cantidad_repetido>5/* && arrayIndicadores.length>1*/){
															if(cantidad<=dividir){colormun="GREEN";}else 
															if(cantidad<=div2a && cantidad>=div2){colormun="YELLOW";}else 
															if(cantidad<=div3a && cantidad>=div3){colormun="BLUE";}else 
															if(cantidad<=div4a && cantidad>=div4){colormun="ORANGE";}else 
															if(cantidad>=div5){colormun="RED";}
														}
														return(
															<>
																<Polygon
																	onClick={(e) => {
																		setActiveMarker(`polygono_${el_municipio}`)
																		setCoordenadasMarker(e.latLng.toJSON())
																	}}
														      paths={la_coordenada.coordenadas}
														      zIndex={2}
														      options={{
											              fillColor: colormun,
											              fillOpacity: 0.35,
											              strokeColor: "black",
											              strokeOpacity: 0.01,
											              clickable: !quitaClic,
											              zIndex: 2
											            }}
														    />
														    {
														    	activeMarker===`polygono_${el_municipio}` ?
															    	<InfoWindow
																      onCloseClick={() => setActiveMarker(null)} //eslint-disable-line
																      position={coordenadasMarker}
																    >
																      <div className="info_window">
														      			<table class="tabla_concen">
														      				<tr>
														      					<td>{idioma ? "Municipio" : "Municipality"}:</td>
														      					<td>{el_municipio}</td>
													      					</tr>
														      				<tr>
														      					<td>{idioma ? window._my_indicadores.filter(p => p.nombre===queson)[0].son : window._my_indicadores.filter(p => p.nombre===queson)[0].sonIn}:</td>
														      					<td>{window.formatoMexico(cantidad)}</td>
													      					</tr>
													      					<tr>
														      					<td colspan="2"><a href={la_coordenada.data} target="new"><b>{idioma ? "Más información" : "More information"}</b></a></td>
													      					</tr>
											      						</table>
																      </div>
																    </InfoWindow>
															    :
															    	null
														    }
													    </>
												    )
													}
												})
											:
												null
										}
										{//ESCUELAS
											unique_municipio.length>0 && queson==='escuelas' ?
												unique_municipio.map(item => {
													let cantidad = arrayEsc.filter(p => p.municipio===item).length
													let la_coordenada = window._arreglo_coordenadas.filter(p => p.municipio.toUpperCase()===item)[0]
													let el_municipio = la_coordenada.municipio

													let colormun = "#bc945a"
													if(cantidad_repetido>5/* && unique_municipio.length>1*/){
														if(cantidad<=dividir){colormun="GREEN";}else 
														if(cantidad<=div2a && cantidad>=div2){colormun="YELLOW";}else 
														if(cantidad<=div3a && cantidad>=div3){colormun="BLUE";}else 
														if(cantidad<=div4a && cantidad>=div4){colormun="ORANGE";}else 
														if(cantidad>=div5){colormun="RED";}
													}
													return(
														<>
															<Polygon
																onClick={(e) => {
																	setActiveMarker(`polygono_${el_municipio}`)
																	setCoordenadasMarker(e.latLng.toJSON())
																}}
													      paths={la_coordenada.coordenadas}
													      zIndex={2}
													      options={{
										              fillColor: colormun,
										              fillOpacity: 0.35,
										              strokeColor: "black",
										              strokeOpacity: 0.01,
										              clickable: !quitaClic,
										              zIndex: 2
										            }}
													    />
													    {
													    	activeMarker===`polygono_${el_municipio}` ?
														    	<InfoWindow
															      onCloseClick={() => setActiveMarker(null)} //eslint-disable-line
															      position={coordenadasMarker}
															    >
															      <div className="info_window">
													      			<table class="tabla_concen">
													      				<tr>
													      					<td>{idioma ? "Municipio" : "Municipality"}:</td>
													      					<td>{el_municipio}</td>
												      					</tr>
													      				<tr>
													      					<td>{idioma ? "Instituciones" : "Colleges"}:</td>
													      					<td>{window.formatoMexico(cantidad)}</td>
												      					</tr>
												      					<tr>
													      					<td colspan="2"><a href={la_coordenada.data} target="new"><b>{idioma ? "Más información" : "More information"}</b></a></td>
												      					</tr>
										      						</table>
															      </div>
															    </InfoWindow>
														    :
														    	null
													    }
												    </>
											    )
												})
											:
												null
										}
										{//ESCUELAS
											arrayEsc.length>0 ?
												<>
													{
														arrayEsc.map(marca =>
															<Marker
																onClick={() => setActiveMarker(`show_escuelas_${marca.es_id}`)}
													      icon={{
													      	url: "img/iconos/escuelas.svg",
													      	scaledSize: new window.google.maps.Size(25,25)
													      }}
													      position={{lat: marca.es_latitud, lng: marca.es_longitud}}
													      //animation={2}
													      clickable={!quitaClic}
													      zIndex={10}
													    >
													    	{
														    	activeMarker===`show_escuelas_${marca.es_id}` ?
															    	<InfoWindow
																      onCloseClick={() => setActiveMarker(null)} //eslint-disable-line
																    >
																      <div className="info_window">
																      	<div class="pa_padin">
															      			{idioma ? "Institución" : "College"}<br/>
															      			<h10>{marca.es_escuela}</h10>
														      			</div>
														      			<div class="modal_linea"></div>
														      			<table class="tabla_modal">
														      				<tr>
														      					<td>{idioma ? "Municipio" : "Municipality"}:</td>
														      					<td>{marca.municipio}</td>
													      					</tr>
													      					<tr>
														      					<td>{idioma ? "Domicilio" : "Address"}:</td>
														      					<td>{marca.es_domicilio}</td>
													      					</tr>
												      						<tr>
																        		<td className="titulo">{idioma ? "Carreras" : "Career"}:</td>
																        		<td>
																        			{
															        					marca.es_carreras.map((car, n) => {
															        						let coma = ", "
															        						if(n===0){
															        							coma = ""
															        						}
															        						return(`${coma}${car.es_carrera}`)
															        					})
															        				}
																        		</td>
																        	</tr>
																        	{
													      						marca.es_web ?
													      							<tr>
															      						<td>{idioma ? "Sitio web" : "Website"}:</td>
															      						<td><a href={marca.es_web} target="new" class="link_modal">{marca.es_web}</a></td>
														      						</tr>
													      						:
													      							null
												      						}
											      						</table>
																      </div>
																    </InfoWindow>
															    :
															    	null
														    }
													    </Marker>
														)
													}
												</>
											:
												null
										}
										{//MERCADOS
											unique_municipio_mercados.length>0 && queson===mercadoTipo ?
												unique_municipio_mercados.map(item => {
													let cantidad = arrayMercados.filter(p => p.municipio===item).length
													let la_coordenada = window._arreglo_coordenadas.filter(p => p.municipio===item)[0]
													let el_municipio = la_coordenada.municipio

													let colormun = "#bc945a"
													if(cantidad_repetido>5/* && unique_municipio_mercados.length>1*/){
														if(cantidad<=dividir){colormun="GREEN";}else 
														if(cantidad<=div2a && cantidad>=div2){colormun="YELLOW";}else 
														if(cantidad<=div3a && cantidad>=div3){colormun="BLUE";}else 
														if(cantidad<=div4a && cantidad>=div4){colormun="ORANGE";}else 
														if(cantidad>=div5){colormun="RED";}
													}
													return(
														<>
															<Polygon
																onClick={(e) => {
																	setActiveMarker(`polygono_${el_municipio}`)
																	setCoordenadasMarker(e.latLng.toJSON())
																}}
													      paths={la_coordenada.coordenadas}
													      zIndex={2}
													      options={{
										              fillColor: colormun,
										              fillOpacity: 0.35,
										              strokeColor: "black",
										              strokeOpacity: 0.01,
										              clickable: !quitaClic,
										              zIndex: 2
										            }}
													    />
													    {
													    	activeMarker===`polygono_${el_municipio}` ?
														    	<InfoWindow
															      onCloseClick={() => setActiveMarker(null)} //eslint-disable-line
															      position={coordenadasMarker}
															    >
															      <div className="info_window">
													      			<table class="tabla_concen">
													      				<tr>
													      					<td>{idioma ? "Municipio" : "Municipality"}:</td>
													      					<td>{el_municipio}</td>
												      					</tr>
													      				<tr>
													      					<td>
													      						{
													      							mercadoTipo==='mercados' ? 
													      								idioma ? "Mercados" : "Markets"
												      								: 
												      									idioma ? "Centrales" : "Supply centers"
													      						}
												      							:
											      							</td>
													      					<td>{window.formatoMexico(cantidad)}</td>
												      					</tr>
												      					<tr>
													      					<td colspan="2"><a href={la_coordenada.data} target="new"><b>{idioma ? "Más información" : "More information"}</b></a></td>
												      					</tr>
										      						</table>
															      </div>
															    </InfoWindow>
														    :
														    	null
													    }
												    </>
											    )
												})
											:
												null
										}
										{//MERCADOS
											arrayMercados.length>0 ?
												<>
													{
														arrayMercados.map((marca, i) =>
															<Marker
																onClick={() => setActiveMarker(`show_mercados_${marca.id}`)}
													      icon={{
													      	url: "img/icos/mercados.png",
													      	scaledSize: new window.google.maps.Size(25,25)
													      }}
													      position={{lat: marca.latitud, lng: marca.longitud}}
													      //animation={2}
													      zIndex={10}
													      clickable={!quitaClic}
													      key={i}
													    >
													    	{
														    	activeMarker===`show_mercados_${marca.id}` ?
															    	<InfoWindow
																      onCloseClick={() => setActiveMarker(null)} //eslint-disable-line
																    >
																      <div className="info_window">
																      	{
																      		mercadoTipo==='mercados' ?
																      			<div class="pa_padin">
																	      			{idioma ? "Mercado" : "Market"}<br/>
																	      			<h10>{marca.nombre}</h10>
																      			</div>
															      			:
															      				<div class="pa_padin">
																	      			{idioma ? "Central" : "Supply center"}<br/>
																	      			<h10>{marca.municipio}</h10>
																      			</div>
																      	}
														      			<div class="modal_linea"></div>
														      			<table class="tabla_modal">
														      				<tr>
														      					<td>{idioma ? "Municipio" : "Municipality"}:</td>
														      					<td>{marca.municipio}</td>
													      					</tr>
													      					{
													      						marca.mujeres>0 || marca.hombres>0 ?
													      							<>
													      								<tr>
																	      					<td>{idioma ? "Comerciantes mujeres" : "Female merchants"}:</td>
																	      					<td>{marca.mujeres}</td>
																      					</tr>
																      					<tr>
																	      					<td>{idioma ? "Comerciantes hombres" : "Male merchants"}:</td>
																	      					<td>{marca.hombres}</td>
																      					</tr>
																      					<tr>
																	      					<td>{idioma ? "Total de comerciantes" : "Total merchants"}:</td>
																	      					<td>{marca.hombres+marca.mujeres}</td>
																      					</tr>
													      							</>
													      						:
													      							null
													      					}
											      						</table>
																      </div>
																    </InfoWindow>
															    :
															    	null
														    }
													    </Marker>
														)
													}
												</>
											:
												null
										}
										{//DENUE
											arrayMunicipiosDenue.length>0 && queson==='establecimientos' ?
												arrayMunicipiosDenue.map(item => {
													let cantidad = item.total
													let la_coordenada = window._arreglo_coordenadas.filter(p => p.municipio===item.municipio)[0]

													let colormun = "#bc945a"
													if(cantidad_repetido>5/* && arrayMunicipiosDenue.length>1*/){
														if(cantidad<=dividir){colormun="GREEN";}else 
														if(cantidad<=div2a && cantidad>=div2){colormun="YELLOW";}else 
														if(cantidad<=div3a && cantidad>=div3){colormun="BLUE";}else 
														if(cantidad<=div4a && cantidad>=div4){colormun="ORANGE";}else 
														if(cantidad>=div5){colormun="RED";}
													}
													return(
														<>
															<Polygon
																onClick={(e) => {
																	setActiveMarker(`polygono_${item.municipio}`)
																	setCoordenadasMarker(e.latLng.toJSON())
																}}
													      paths={la_coordenada.coordenadas}
													      zIndex={2}
													      options={{
										              fillColor: colormun,
										              fillOpacity: 0.35,
										              strokeColor: "black",
										              strokeOpacity: 0.01,
										              clickable: !quitaClic,
										              zIndex: 2
										            }}
													    />
													    {
													    	activeMarker===`polygono_${item.municipio}` ?
														    	<InfoWindow
															      onCloseClick={() => setActiveMarker(null)} //eslint-disable-line
															      position={coordenadasMarker}
															    >
															      <div className="info_window">
															      	<table class="tabla_concen">
													      				<tr>
													      					<td>{idioma ? "Municipio" : "Municipality"}:</td>
													      					<td>{item.municipio}</td>
												      					</tr>
													      				<tr>
													      					<td>{idioma ? "Establecimientos" : "Establishments"}:</td>
													      					<td>{window.formatoMexico(item.total)}</td>
												      					</tr>
												      					<tr>
													      					<td colspan="2"><a href={la_coordenada.data} target="new"><b>{idioma ? "Más información" : "More information"}</b></a></td>
												      					</tr>
										      						</table>
															      </div>
															    </InfoWindow>
														    :
														    	null
													    }
												    </>
											    )
												})
											:
												null
										}
										{//DENUE
											arrayDenue.length>0 && arrayDenue.length<501 ?
												<>
													{
														arrayDenue.map(marca =>
															<Marker
																onClick={() => setActiveMarker(`show_comercios_${marca.id}`)}
													      icon={{
													      	url: "img/icos/establecimientos.png",
													      	scaledSize: new window.google.maps.Size(25,25)
													      }}
													      position={{lat: parseFloat(marca.latitud), lng: parseFloat(marca.longitud)}}
													      //animation={2}
													      clickable={!quitaClic}
													      zIndex={10}
													    >
													    	{
														    	activeMarker===`show_comercios_${marca.id}` ?
															    	<InfoWindow
																      onCloseClick={() => setActiveMarker(null)} //eslint-disable-line
																    >
																      <div className="info_window">
																      	<div class="pa_padin">
															      			{idioma ? "Establecimiento" : "Establishment"}<br/>
															      			<h10>{marca.nom_estab}</h10>
														      			</div>
														      			<div class="modal_linea"></div>
														      			<table class="tabla_modal">
														      				<tr>
														      					<td>{idioma ? "Actividad económica" : "Economic activity"}:</td>
														      					<td>{marca.nombre_act}</td>
													      					</tr>
													      					<tr>
														      					<td>{idioma ? "Tamaño" : "Size"}:</td>
														      					<td>
														      						{
														      							marca.per_ocu==='251 y más personas' ?
														      								idioma ? 'Grande' : 'Big'
														      							: marca.per_ocu==='51 a 100 personas' || marca.per_ocu==='101 a 250 personas' ?
														      								idioma ? 'Mediana' : 'Medium'
														      							: marca.per_ocu==='11 a 30 personas' || marca.per_ocu==='31 a 50 personas' ?
														      								idioma ? 'Pequeña' : 'Small'
														      							:
														      								'Micro'
														      						}
													      						</td>
													      					</tr>
													      					<tr>
														      					<td>{idioma ? "Municipio" : "Municipality"}:</td>
														      					<td>{marca.municipio}</td>
													      					</tr>
												      						<tr>
														      					<td>{idioma ? "C.P" : "P.C"}:</td>
														      					<td>{marca.cod_postal}</td>
													      					</tr>
													      					{
													      						marca.localidad ?
													      							<tr>
															      						<td>{idioma ? "Localidad" : "Locality"}:</td>
															      						<td>{marca.localidad}</td>
														      						</tr>
													      						:
													      							null
												      						}
													      					{
													      						marca.telefono ?
													      							<tr>
															      						<td>{idioma ? "Teléfono" : "Telephone"}:</td>
															      						<td>{marca.telefono}</td>
														      						</tr>
													      						:
													      							null
												      						}
												      						{
													      						marca.correoelec ?
													      							<tr>
															      						<td>{idioma ? "Correo electrónico" : "Email"}:</td>
															      						<td>{marca.correoelec.toLowerCase()}</td>
														      						</tr>
													      						:
													      							null
												      						}
																        	{
													      						marca.www ?
													      							<tr>
															      						<td>{idioma ? "Sitio web" : "Website"}:</td>
															      						<td><a href={marca.www} target="new" class="link_modal">{marca.www.toLowerCase()}</a></td>
														      						</tr>
													      						:
													      							null
												      						}
											      						</table>
																      </div>
																    </InfoWindow>
															    :
															    	null
														    }
													    </Marker>
														)
													}
												</>
											:
												null
										}
										{//LABORAL
											arrayLaboral.length>0 && queson==='trabajadores' ?
												arrayLaboral.map(item => {
													let cantidad = item.total
													let la_coordenada = window._arreglo_coordenadas.filter(p => window.normalize(p.municipio).toUpperCase()===item._id)[0]
													let el_municipio = la_coordenada.municipio

													let colormun = "#bc945a"
													if(cantidad_repetido>5/* && arrayLaboral.length>1*/){
														if(cantidad<=dividir){colormun="GREEN";}else 
														if(cantidad<=div2a && cantidad>=div2){colormun="YELLOW";}else 
														if(cantidad<=div3a && cantidad>=div3){colormun="BLUE";}else 
														if(cantidad<=div4a && cantidad>=div4){colormun="ORANGE";}else 
														if(cantidad>=div5){colormun="RED";}
													}
													return(
														<>
															<Polygon
																onClick={(e) => {
																	setActiveMarker(`polygono_${item._id}`)
																	setCoordenadasMarker(e.latLng.toJSON())
																}}
													      paths={la_coordenada.coordenadas}
													      zIndex={2}
													      options={{
										              fillColor: colormun,
										              fillOpacity: 0.35,
										              strokeColor: "black",
										              strokeOpacity: 0.01,
										              clickable: !quitaClic,
										              zIndex: 2
										            }}
													    />
													    {
													    	activeMarker===`polygono_${item._id}` ?
														    	<InfoWindow
															      onCloseClick={() => setActiveMarker(null)} //eslint-disable-line
															      position={coordenadasMarker}
															    >
															      <div className="info_window">
															      	<table class="tabla_concen">
													      				<tr>
													      					<td>{idioma ? "Municipio" : "Municipality"}:</td>
													      					<td>{el_municipio}</td>
												      					</tr>
													      				<tr>
													      					<td>{idioma ? "Trabajadores" : "Workers"}:</td>
													      					<td>{window.formatoMexico(item.total)}</td>
												      					</tr>
												      					<tr>
													      					<td colspan="2"><a href={la_coordenada.data} target="new"><b>{idioma ? "Más información" : "More information"}</b></a></td>
												      					</tr>
										      						</table>
															      </div>
															    </InfoWindow>
														    :
														    	null
													    }
												    </>
											    )
												})
											:
												null
										}


										
										
										{//CAPAS BD
											window._my_capas.filter(p => p.tipo!=='buscar').length>0 ?
												window._my_capas.filter(p => p.tipo!=='buscar').map(item => { //eslint-disable-line
													if(eval(item.id)>0){ //eslint-disable-line
														//console.log(item.id)
														if(item.tipo==='kml'){
															return(
																<KmlLayer 
																	url={item.capa}
																	zIndex={item.posicion}
																	options={{ 
																		preserveViewport: true,
    																suppressInfoWindows: quitaClic,
																	}}
																	onClick={(e) => {
																		funcionCirculo(e)
																	}}
																/>
															)
														}else if(item.tipo==='kml_arreglo'){
															if(item.arreglo.length>0){
																return(
																	<>
																		{
																			item.arreglo.map(kml => 
																				<KmlLayer 
																					url={kml}
																					zIndex={item.posicion}
																					options={{ 
																						preserveViewport: true,
				    																suppressInfoWindows: quitaClic,
																					}}
																					onClick={(e) => {
																						funcionCirculo(e)
																					}}
																				/>
																			)
																		}
																	</>
																)
															}
														}
														/*
														else if(item.tipo==='marker'){
															return(
																<Marker
														      onClick={() => {
																		if(item.infowindow){
																			setActiveMarker(item.id)
																		}else{
																			setModal(true)
																			if(item.informacion){
																				setInformacion(item.informacion)
																			}
																			if(item.fotografia){
																				setFotografia(item.fotografia)
																			}
																		}
																	}}
														      icon={item.icon}
														      position={{lat: item.lat, lng: item.lng}}
														      animation={2}
														      zIndex={item.posicion}
														    >
														    	{
															    	item.infowindow && activeMarker===item.id ?
																    	<InfoWindow
																	      onCloseClick={() => setActiveMarker(null)} //eslint-disable-line
																	    >
																	      <div className="info_window">
																	        {
																        		item.informacion ?
																		        	<div dangerouslySetInnerHTML={{ __html: item.informacion }} className="texto_modal"/>
																	          : 
																	          	null
																          }
																          {
																        		item.fotografia ?
																	          	<img src={item.fotografia} alt="Fotografía" className="fotografia_modal"/>
																	          :
																	          	null
																          }
																	      </div>
																	    </InfoWindow>
																    :
																    	null
															    }
														    </Marker>
															)
															/*
															return(
																<>
																	<Marker
															      onClick={() => {
																			if(item.infowindow){
																				if(elEval>0){cambia = 0}
																				elSetEval = `set${item.id}_info(${cambia})`
																				eval(elSetEval) //eslint-disable-line
																			}else{
																				if(item.informacion){
																					setModal(true)
																					setInformacion(item.informacion)
																				}
																				if(item.fotografia){
																					setModal(true)
																					setFotografia(item.fotografia)
																				}
																			}
																		}}
															      icon={item.icon}
															      position={{lat: item.lat, lng: item.lng}}
															      animation={2}
															      zIndex={item.posicion}
															    />
															    {
															    	item.infowindow && elEval>0 ?
																    	<InfoWindow
																	      position={{lat: item.lat, lng: item.lng}}
																	      zIndex={item.posicion}
																	      onCloseClick={() => eval(elSetEvalClose)} //eslint-disable-line
																	    >
																	      <div className="info_window">
																	        {
																        		item.informacion ?
																		        	<div dangerouslySetInnerHTML={{ __html: item.informacion }} className="texto_modal"/>
																	          : 
																	          	null
																          }
																          {
																        		item.fotografia ?
																	          	<img src={item.fotografia} alt="Fotografía" className="fotografia_modal"/>
																	          :
																	          	null
																          }
																	      </div>
																	    </InfoWindow>
																    :
																    	null
															    }
														    </>
															)
															*//*
														}*/else if(item.tipo==='polygon'){
															return(
																<Polygon
																	onClick={() => {
																		if(item.informacion){
																			setModal(true)
																			if(idioma){
																				setInformacion(item.informacion)
																			}else{
																				setInformacion(item.informacionIn)
																			}
																		}else if(item.fotografia){
																			setModal(true)
																			setFotografia(item.fotografia)
																		}
																	}}
														      paths={item.coordenadas}
														      options={{
														      	fillColor: item.fillColor,
											              fillOpacity: item.fillOpacity,
											              strokeColor: item.strokeColor,
											              strokeOpacity: item.strokeOpacity,
											              strokeWeight: item.strokeWeight,
											              clickable: !quitaClic,
											              //clickable: false,
											              //draggable: false,
											              //editable: false,
											              //geodesic: false,
											              zIndex: item.posicion
														      }}
														      zIndex={item.posicion}
														    />
															)
														}else if(item.tipo==='polygon_arreglo'){
															if(item.arreglo.length>0){
																return(
																	<>
																		{
																			item.arreglo.map(poly => 
																				<Polygon
																					onClick={() => {
																						if(poly.informacion){
																							setModal(true)
																							if(idioma){
																								setInformacion(poly.informacion)
																							}else{
																								setInformacion(poly.informacionIn)
																							}
																						}
																					}}
																		      paths={poly.coordenadas}
																		      options={{
																		      	fillColor: poly.fillColor,
															              fillOpacity: item.fillOpacity,
															              strokeColor: item.strokeColor,
															              strokeOpacity: item.strokeOpacity,
															              strokeWeight: item.strokeWeight,
															              clickable: !quitaClic,
															              zIndex: item.posicion
																		      }}
																		    />
																	    )
																    }
															    </>
														    )
													    }
														}else if(item.tipo==='linea'){
															if(item.arreglo.length>0){
																return(
																	<>
																		{
																			item.arreglo.map(linea => 
																				<Polyline
																		      onClick={() => {
																						setModal(true)
																						if(linea.informacion){
																							if(idioma){
																								setInformacion(linea.informacion)
																							}else{
																								setInformacion(linea.informacionIn)
																							}
																						}else if(linea.fotografia){
																							setFotografia(linea.fotografia)
																						}else if(item.informacion){
																							if(idioma){
																								setInformacion(item.informacion)
																							}else{
																								setInformacion(item.informacionIn)
																							}
																						}else if(item.fotografia){
																							setInformacion(item.fotografia)
																						}
																					}}
																					path={linea}
			      															options={{
			      																strokeColor: item.strokeColor,
															              strokeWeight: item.strokeWeight,
															              fillColor: item.fillColor,
															              clickable: !quitaClic,
															              zIndex: item.posicion
			      															}}
																		      zIndex={item.posicion}
																		    />
																			)
																		}
																	</>
																)
															}
														}else if(item.tipo==='marker'){
															if(item.arreglo.length>0){
																return(
																	<>
																		{
																			item.arreglo.map(marca => 
																				<Marker
																		      onClick={() => {
																						if(marca.infowindow || item.infowindow){
																							setActiveMarker(`${item.id}_${marca.id}`)
																						}else{
																							setModal(true)
																							if(marca.informacion){
																								if(idioma){
																									setInformacion(marca.informacion)
																								}else{
																									setInformacion(marca.informacionIn)
																								}
																							}else if(marca.fotografia){
																								setFotografia(marca.fotografia)
																							}
																						}
																					}}
																		      icon={{
																		      	url: marca.icon ? marca.icon : item.imagen,
																		      	scaledSize: new window.google.maps.Size(25,25)
																		      }}
																		      position={{lat: marca.lat, lng: marca.lng}}
																		      animation={2}
																		      zIndex={item.posicion}
																		      clickable={!quitaClic}
																		    >
																		    	{
																			    	(marca.infowindow || item.infowindow) && activeMarker===`${item.id}_${marca.id}` ?
																				    	<InfoWindow
																					      onCloseClick={() => setActiveMarker(null)}
																					    >
																					      <div className="info_window">
																					        {
																				        		marca.informacion ?
																						        	<div dangerouslySetInnerHTML={{ __html: idioma ? marca.informacion : marca.informacionIn }} className="texto_modal"/>
																					          : 
																					          	null
																				          }
																				          {
																				        		marca.fotografia ?
																					          	<img src={marca.fotografia} alt="Fotografía" className="fotografia_modal"/>
																					          :
																					          	null
																				          }
																				          {
																				          	item.id==='Capa_parques' ?
																				          		<center><br/><verficha onClick={() => setModalParque(marca.id)}>{idioma ? 'Análisis poblacional' : 'Population analysis'}</verficha></center>
																				          	: item.id==='Capa_Presidencias' ?
																				          		<div>
																								      	<div class="pa_padin">
																							      			{idioma ? "Presidencia municipal" : "City Hall"}<br/>
																							      			<h10>{marca.municipio}</h10>
																						      			</div>
																						      			<div class="modal_linea"></div>
																						      			<table class="tabla_modal">
																						      				<tr>
																						      					<td>{idioma ? "Dirección" : "Address"}:</td>
																						      					<td>{marca.direccion}</td>
																					      					</tr>
																					      					<tr>
																						      					<td>{idioma ? "Teléfono" : "Telephone"}:</td>
																						      					<td>{marca.telefono}</td>
																					      					</tr>
																								        	{
																					      						marca.correo ?
																					      							<tr>
																							      						<td>{idioma ? "Correo electrónico" : "Email"}:</td>
																							      						<td><a href={`mailto:${marca.correo}`} class="link_modal">{marca.correo}</a></td>
																						      						</tr>
																					      						:
																					      							null
																				      						}
																				      						{
																					      						marca.www ?
																					      							<tr>
																							      						<td>{idioma ? "Sitio web" : "Website"}:</td>
																							      						<td><a href={marca.www} target="new" class="link_modal">{marca.www}</a></td>
																						      						</tr>
																					      						:
																					      							null
																				      						}
																				      						{
																					      						marca.face ?
																					      							<tr>
																							      						<td>{idioma ? "Redes sociales" : "Social networks"}:</td>
																							      						<td><a href={marca.face} target="new" class="link_modal">Facebook</a></td>
																						      						</tr>
																					      						:
																					      							null
																				      						}
																			      						</table>
																								      </div>
																				          	:
																				          		null
																				          }
																					      </div>
																					    </InfoWindow>
																				    :
																				    	null
																			    }
																		    </Marker>
																			)
																		}
																	</>
																)
															}
														}
													}
												})
											:
												null
										}
										{/*
										<Polygon
									      paths={window._arreglo_coordenadas[47].coordenadas}
									    />*/}
										{/*capaArco*/}
										{/*<KmlLayer url="http://148.223.224.75/mapa/capas/municipios_c.kml" zIndex={1}/>*/}
										{/*}
										<Marker
									      position={{lat: 19.41, lng: -98.952804027}}
									    />*/}
									    {/*
									    <Polygon
									      paths={window._pachucaCoords}
									      options={options}
									    />*/}
									  	{/*}
									    <Marker
									      icon={{
									        path: "M8 12l-4.7023 2.4721.898-5.236L.3916 5.5279l5.2574-.764L8 0l2.3511 4.764 5.2574.7639-3.8043 3.7082.898 5.236z",
									        fillColor: "yellow",
									        fillOpacity: 0.9,
									        scale: 2,
									        strokeColor: "gold",
									        strokeWeight: 2,
									      }}
									      position={{lat: 19.91, lng: -98.95}}
									    />
									  */}
									  {/*}
									    <InfoWindow
									       position={{lat: 19.9106203864, lng: -98.952804027}}
									    >
									      <div className="info_window">
									        <h1>InfoWindow</h1>
									      </div>
									    </InfoWindow>
									  */}
									  {
									  	miUbicacion ? 
									  		<Marker
										      position={{lat: miUbicacion.latitude, lng: miUbicacion.longitude}}
										      onClick={() => setActiveMarker("mi_ubicacion")}
										      icon={{
										      	//url: "img/icos/establecimientos.png",
										      	scaledSize: new window.google.maps.Size(25,25)
										      }}
										      animation={2}
										      clickable={!quitaClic}
										      zIndex={10}
										    >
										    	{
										    		activeMarker==="mi_ubicacion" ?
										    			<InfoWindow
										    				onCloseClick={() => setActiveMarker(null)}
										    			>
													      <div className="info_window">
													        <h10>{idioma ? "Mi ubicación" : "My location"}</h10>
													      </div>
													    </InfoWindow>
										    		:
										    			null
										    	}
										    </Marker>
									    :
									    	null
									  }
									</GoogleMap>
								</div>
							: 
								<div className="loading_map">{idioma ? "Cargando" : "Loading"}...</div>
						}
					{/*</LoadScript>*/}
				</div>
			</div>
			<Modal
        show={modal}
        onHide={() => cerrarModal()}
        size="lg"
        centered
        className="my-modal"
      >
        {
        	buscar ?
	        	<Modal.Header closeButton>
		          <Modal.Title>
		          	{
		          		buscar==='show_circulo' ?
		          			<div>{idioma ? 'Información del radio' : 'Radius information'} ({circuloInfo.radius/1000} {idioma ? 'kilómetros' : 'kilometres'})</div>
		          		: buscar==='show_indicadores' ?
		          			idioma ? indicadores : window._my_indicadores.filter(p => p.nombre===indicadores)[0].nombreIn
		          		: window._my_capas.filter(p => p.id===buscar).length>0 ?
		          			idioma ? window._my_capas.filter(p => p.id===buscar)[0].nombre : window._my_capas.filter(p => p.id===buscar)[0].nombreIn
		          		:
		          			null
		          	}
		          </Modal.Title>
		        </Modal.Header>
	        :
	        	null
        }
        <Modal.Body>
        	{
        		informacion ?
		        	<div className="div_modal_bot">
			          <div className="cerrar_modal" onClick={() => cerrarModal()}><img src="img/cerrar.svg" title={idioma ? "Cerrar" : "Close"} alt="Cerrar"/></div>
			          <div id="cl"></div>
			          {
			          	informacion.info ? 
			          		<div>
			          			<div dangerouslySetInnerHTML={{ __html: idioma ? informacion.info : informacion.infoIn }} className="texto_modal"/>
			          			{
			          				informacion.carousel ?
			          					<>
				          					<Carousel fade nextIcon={<img src="img/next.svg" className="caro_next" alt="Siguiente"/>} prevIcon={<img src="img/next.svg" className="caro_next caro_prev" alt="Atras"/>}>
												      {
												      	informacion.carousel.map((item, i) =>
											      			<Carousel.Item key={i}>
														        <img
														          className="d-block w-100"
														          src={item.imagen}
														          alt="Slider"
														        />
														        <Carousel.Caption>
														          {item.titulo ? <h3>{item.titulo}</h3> : null}
														          {item.texto ? <p>{item.texto}</p> : null}
														          {item.www ? <a href={item.www} className="car_url" target="new"><img src="img/www.svg" alt="www"/></a> : null}
														        </Carousel.Caption>
														      </Carousel.Item>
											      		)
												      }
												    </Carousel>
												    <div className="funte_modal">{informacion.fuente}</div>
											    </>
			          				:
			          					null
			          			}
			          		</div>
			          	:
			          		<div dangerouslySetInnerHTML={{ __html: informacion }} className="texto_modal"/>
			          }
		          </div>
	          : fotografia ?
	          	<img src={fotografia} alt="Fotografía" className="fotografia_modal"/>
	          :
	          	<div className="div_modal_bot" >
	          		{/*
		          		window._my_capas.filter(p => p.id===buscar).length>0 ?
		          			window._my_capas.filter(p => p.id===buscar)[0].nombre
		          		:
		          			null
		          	*/}
	          		{
	          			buscar==='show_indicadores' ?
	          				<Form>
	          					{
							      		arrayIndicadores.length>0 && indicadores===indicadorPrincipal ?
							      			<div>
							      				{/*indicadorPrincipal ? <div className="f_right"><b>{indicadorPrincipal}</b></div> : null*/}
							      				<div id="cl"></div>
								      			<div className="f_right">{idioma ? "Resultados encontrados" : "Results found"}: <b>{arrayIndicadores.length}</b></div>
								      			<div id="cl"></div>
								      			<div className="flex_iconos_botones">
															{
									      				arrayIndicadores.length<2001 ?
											      			<CSVLink
																		headers={window._my_indicadores.filter(p => p.nombre===indicadorPrincipal)[0].indicadores}
																		data={arrayIndicadores}
																		target="_blank"
																		//separator={"|"}
																		filename={"indicadores.csv"}
																		className="botones_iconos"
																	>
																		<img src="img/ico_excel.svg" title={idioma ? "Descargar CSV" : "Download CSV"} alt="CSV"/>
																	</CSVLink>
																:
																	null
															}
															<div className="botones_iconos" onClick={() => funcionGraficas(buscar)}>
																<img src="img/ico_grafica.svg" title={idioma ? "Ver gráficas" : "See graphs"} alt="Gráficas"/>
															</div>
															<div className="botones_iconos" id={window._my_indicadores.filter(p => p.nombre===queson).length>0 ? 'marcado' : ''} onClick={() => {
																if(window._my_indicadores.filter(p => p.nombre===queson).length>0){
																	setQueson("")
																}else{
																	setQueson(indicadorPrincipal)
																}
															}}>
																<img src="img/ico_mapa.svg" title={idioma ? "Ver concentración por municipio" : "See concentration by municipality"} alt="Concentración"/>
															</div>
														</div>
													</div>
							      		:
							      			null
							      	}
							      	{
							      		localStorage._ROL ?
							      			<>
										      	{idioma ? "Tipo de región" : "Region type"}:
											      <div className="flex_regiones">
									        		<Button variant={region==='Macrorregión' ? "info" : "secondary"} onClick={() => {
									        			if(region==='Macrorregión'){
									      					setRegion('')
									      				}else{
									        				setRegion('Macrorregión')
									        			}
									        			setIndicadoresRegion([])
									        			setIndicadoresMunicipio([])
									        		}}>{idioma ? "Macrorregión" : "Macroregion"}</Button>
									        		<Button variant={region==='Microrregión' ? "info" : "secondary"} onClick={() => {
									      				if(region==='Microrregión'){
									      					setRegion('')
									      				}else{
									        				setRegion('Microrregión')
									        			}
									        			setIndicadoresRegion([])
									        			setIndicadoresMunicipio([])
									        		}}>{idioma ? "Región" : "Region"}</Button>
								      			</div>
								      			{
								      				region ?
								      					<Form.Group>
													        <div id="mi_espacio"></div>
													        <Typeahead
													          id="basic-typeahead-multiple"
													          multiple
													          labelKey="regiones_lista"
													          onChange={(e) => {
													          	setIndicadoresRegion(e)

													          	let array_pa_municipios = indicadoresMunicipio
													          	if(e.length>0){
																      	e.map(item =>
																      		window._arreglo_coordenadas.filter(p => p.marcroregion===item || p.microregion===item).map(municipio => { //eslint-disable-line
																      			if(array_pa_municipios.filter(p => p===municipio.municipio).length>0){
																      				array_pa_municipios = array_pa_municipios.filter(p => p===municipio.municipio)
																      			}else{
																      				array_pa_municipios.push(municipio.municipio)
																      			}
																      		})
																      	)
																      }else{
																      	array_pa_municipios = []
																      }
																      setIndicadoresMunicipio(array_pa_municipios)
													          }}
													          options={region ? window._my_regiones.filter(p => p.nombre===region)[0].regiones_lista : window._my_todas_regiones}
													          selected={indicadoresRegion}
													          emptyLabel={idioma ? "No hay resultados." : "No results."}
													        />
													      </Form.Group>
												      :
												      	null
								      			}
							      			</>
						      			:
						      				null
						      		}
								      <Form.Group>
								        <Form.Label>{idioma ? "Municipio" : "Municipality"}:</Form.Label>
								        <Typeahead
								          id="basic-typeahead-multiple"
								          multiple
								          onChange={setIndicadoresMunicipio}
								          options={window._my_municipios}
								          selected={indicadoresMunicipio}
								          emptyLabel={idioma ? "No hay resultados." : "No results."}
								        />
								      </Form.Group>
	          					<Form.Group>
								        <Form.Label>{idioma ? "Indicador" : "Indicator"}:</Form.Label>
								        <Form.Select onChange={(event) => {
								        	//alert(event.target.value)
								        	setIndicadores(event.target.value)
								        	setIndicadoresVariable([])
								        }}>
								        	{
								        		window._my_indicadores.map((item, i) =>
							        				<option key={i} value={item.nombre} selected={indicadores===item.nombre ? true : false}>{idioma ? item.nombre : item.nombreIn}</option>
							        			)
								        	}
								        </Form.Select>
								      </Form.Group>
								      {
								      	window._my_indicadores.filter(p => p.indicadores && p.nombre===indicadores).length>0 ?
								      		window._my_indicadores.filter(p => p.nombre===indicadores)[0].indicadores.filter(p => p.label!=='Municipio').length>1 ?
									      		<Form.Group>
											        <Form.Label>{idioma ? "Tipo" : "Type"}:</Form.Label>
											        <Typeahead
											        	id="basic-typeahead-multiple"
											          multiple
											          labelKey="label"
											          onChange={setIndicadoresVariable}
											          options={idioma ? window._my_indicadores.filter(p => p.nombre===indicadores)[0].indicadores.filter(p => p.label!=='Municipio') : window._my_indicadores.filter(p => p.nombre===indicadores)[0].indicadoresIn.filter(p => p.label!=='Municipality')}
											          selected={indicadoresVariable}
											          emptyLabel={idioma ? "No hay resultados." : "No results."}
											        />
											      </Form.Group>
										      :
										      	null
								      	:
								      		null
								      }
	          				</Form>
	          			: buscar==='show_escuelas' ?
				          	<Form>
				          		{
							      		arrayEsc.length>0 ?
							      			<div>
								      			<div className="f_right">{idioma ? "Resultados encontrados" : "Results found"}: <b>{arrayEsc.length}</b></div>
								      			<div id="cl"></div>
								      			<div className="flex_iconos_botones">
															{
									      				arrayEsc.length<2001 ?
											      			<CSVLink
																		headers={window._escuelas_header}
																		data={arrayEsc}
																		target="_blank"
																		//separator={"|"}
																		filename={"educacion_superior.csv"}
																		className="botones_iconos"
																	>
																		<img src="img/ico_excel.svg" title={idioma ? "Descargar CSV" : "Download CSV"} alt="CSV"/>
																	</CSVLink>
																:
																	null
															}
															<div className="botones_iconos" onClick={() => funcionGraficas(buscar)}>
																<img src="img/ico_grafica.svg" title={idioma ? "Ver gráficas" : "See graphs"} alt="Gráficas"/>
															</div>
															<div className="botones_iconos" id={queson==='escuelas' ? 'marcado' : ''} onClick={() => {
																if(queson==='escuelas'){
																	setQueson("")
																}else{
																	setQueson("escuelas")
																}
															}}>
																<img src="img/ico_mapa.svg" title={idioma ? "Ver concentración por municipio" : "See concentration by municipality"} alt="Concentración"/>
															</div>
														</div>
													</div>
							      		:
							      			null
							      	}
				          		{/*
								      <Form.Group className="mb-3" controlId="formBasicEmail">
								        <Form.Label>Email address</Form.Label>
								        <Form.Control type="email" placeholder="Enter email" />
								        <Form.Text className="text-muted">
								          We'll never share your email with anyone else.
								        </Form.Text>
								      </Form.Group>


					          	<Form.Group>
								        <Form.Label>Carrera:</Form.Label>
								        <Typeahead
								          id="basic-typeahead-multiple"
								          labelKey="es_carrera"
								          multiple
								          onChange={setCarreraEscuelas}
								          options={window._capa_escuelas}
								          //placeholder="Choose several states..."
								          selected={carreraEscuelas}
								        />
								      </Form.Group>
								    	*/}
								    	{
							      		localStorage._ROL ?
							      			<>
										      	{idioma ? "Tipo de región" : "Region type"}:
											      <div className="flex_regiones">
									        		<Button variant={region==='Macrorregión' ? "info" : "secondary"} onClick={() => {
									        			if(region==='Macrorregión'){
									      					setRegion('')
									      				}else{
									        				setRegion('Macrorregión')
									        			}
									        			setRegionEscuelas([])
									        			setMunicipioEscuelas([])
									        		}}>{idioma ? "Macrorregión" : "Macroregion"}</Button>
									        		<Button variant={region==='Microrregión' ? "info" : "secondary"} onClick={() => {
									      				if(region==='Microrregión'){
									      					setRegion('')
									      				}else{
									        				setRegion('Microrregión')
									        			}
									        			setRegionEscuelas([])
									        			setMunicipioEscuelas([])
									        		}}>{idioma ? "Región" : "Region"}</Button>
								      			</div>
								      			{
								      				region ?
								      					<Form.Group>
													        <div id="mi_espacio"></div>
													        <Typeahead
													          id="basic-typeahead-multiple"
													          multiple
													          labelKey="regiones_lista"
													          onChange={(e) => {
													          	setRegionEscuelas(e)

													          	let array_pa_municipios = municipioEscuelas
													          	if(e.length>0){
																      	e.map(item =>
																      		window._arreglo_coordenadas.filter(p => p.marcroregion===item || p.microregion===item).map(municipio => { //eslint-disable-line
																      			if(array_pa_municipios.filter(p => p===municipio.municipio).length>0){
																      				array_pa_municipios = array_pa_municipios.filter(p => p===municipio.municipio)
																      			}else{
																      				array_pa_municipios.push(municipio.municipio)
																      			}
																      		})
																      	)
																      }else{
																      	array_pa_municipios = []
																      }
																      setMunicipioEscuelas(array_pa_municipios)
													          }}
													          options={region ? window._my_regiones.filter(p => p.nombre===region)[0].regiones_lista : window._my_todas_regiones}
													          selected={regionEscuelas}
													          emptyLabel={idioma ? "No hay resultados." : "No results."}
													        />
													      </Form.Group>
												      :
												      	null
								      			}
							      			</>
						      			:
						      				null
					      			}
								      <Form.Group>
								        <Form.Label>{idioma ? "Municipio" : "Municipality"}:</Form.Label>
								        <Typeahead
								          id="basic-typeahead-multiple"
								          multiple
								          onChange={setMunicipioEscuelas}
								          options={window._my_municipios}
								          selected={municipioEscuelas}
								          emptyLabel={idioma ? "No hay resultados." : "No results."}
								        />
								      </Form.Group>
								    	<Form.Group>
								        <Form.Label>{idioma ? "Carrera" : "Career"}:</Form.Label>
								        <Typeahead
								        	id="basic-typeahead-multiple"
								          multiple
								          onChange={setCarreraEscuelas}
								          options={window.unicas_es_carreras}
								          selected={carreraEscuelas}
								          emptyLabel={idioma ? "No hay resultados." : "No results."}
								        />
								      </Form.Group>
								      <Form.Group>
								        <Form.Label>{idioma ? "Institución" : "College"}:</Form.Label>
								        <Typeahead
								        	id="basic-typeahead-multiple"
								          multiple
								          onChange={setInstitucionEscuelas}
								          options={window.unique_institucion}
								          selected={institucionEscuelas}
								          emptyLabel={idioma ? "No hay resultados." : "No results."}
								        />
								      </Form.Group>
								      {/*
								      <Form.Group>
								        <Form.Label>Escuela:</Form.Label>
								        <Typeahead
								        	id="basic-typeahead-multiple"
								          multiple
								          onChange={setEscuelaEscuelas}
								          options={window.unique_escuelas}
								          selected={escuelaEscuelas}
								          emptyLabel={idioma ? "No hay resultados." : "No results."}
								        />
								      </Form.Group>
								      <div id="cl" className="minimo_espacio"></div>
											*/}  
								    </Form>
							    : buscar==='show_mercados' ?
				          	<Form>
				          		{
							      		arrayEsc.length>0 ?
							      			<div>
								      			<div className="f_right">{idioma ? "Resultados encontrados" : "Results found"}: <b>{arrayMercados.length}</b></div>
								      			<div id="cl"></div>
								      			<div className="flex_iconos_botones">
															{
									      				arrayMercados.length<2001 ?
											      			<CSVLink
																		headers={window._mercados_header}
																		data={arrayMercados}
																		target="_blank"
																		//separator={"|"}
																		filename={"mercados-centrales.csv"}
																		className="botones_iconos"
																	>
																		<img src="img/ico_excel.svg" title={idioma ? "Descargar CSV" : "Download CSV"} alt="CSV"/>
																	</CSVLink>
																:
																	null
															}
															<div className="botones_iconos" onClick={() => funcionGraficas(buscar)}>
																<img src="img/ico_grafica.svg" title={idioma ? "Ver gráficas" : "See graphs"} alt="Gráficas"/>
															</div>
															<div className="botones_iconos" id={queson===mercadoTipo ? 'marcado' : ''} onClick={() => {
																if(queson===mercadoTipo){
																	setQueson("")
																}else{
																	setQueson(mercadoTipo)
																}
															}}>
																<img src="img/ico_mapa.svg" title={idioma ? "Ver concentración por municipio" : "See concentration by municipality"} alt="Concentración"/>
															</div>
														</div>
													</div>
							      		:
							      			null
							      	}
							      	{
							      		localStorage._ROL ?
							      			<>
										      	{idioma ? "Tipo de región" : "Region type"}:
											      <div className="flex_regiones">
									        		<Button variant={region==='Macrorregión' ? "info" : "secondary"} onClick={() => {
									        			if(region==='Macrorregión'){
									      					setRegion('')
									      				}else{
									        				setRegion('Macrorregión')
									        			}
									        			setMercadoRegion([])
									        			setMercadoMunicipio([])
									        		}}>{idioma ? "Macrorregión" : "Macroregion"}</Button>
									      			<Button variant={region==='Microrregión' ? "info" : "secondary"} onClick={() => {
									      				if(region==='Microrregión'){
									      					setRegion('')
									      				}else{
									        				setRegion('Microrregión')
									        			}
									        			setMercadoRegion([])
									        			setMercadoMunicipio([])
									        		}}>{idioma ? "Región" : "Region"}</Button>
								      			</div>
								      			{
								      				region ?
								      					<Form.Group>
													        <div id="mi_espacio"></div>
													        <Typeahead
													          id="basic-typeahead-multiple"
													          multiple
													          labelKey="regiones_lista"
													          onChange={(e) => {
													          	setMercadoRegion(e)

													          	let array_pa_municipios = mercadoMunicipio
													          	if(e.length>0){
																      	e.map(item =>
																      		window._arreglo_coordenadas.filter(p => p.marcroregion===item || p.microregion===item).map(municipio => { //eslint-disable-line
																      			if(array_pa_municipios.filter(p => p===municipio.municipio).length>0){
																      				array_pa_municipios = array_pa_municipios.filter(p => p===municipio.municipio)
																      			}else{
																      				array_pa_municipios.push(municipio.municipio)
																      			}
																      		})
																      	)
																      }else{
																      	array_pa_municipios = []
																      }
																      setMercadoMunicipio(array_pa_municipios)
													          }}
													          options={region ? window._my_regiones.filter(p => p.nombre===region)[0].regiones_lista : window._my_todas_regiones}
													          selected={mercadoRegion}
													          emptyLabel={idioma ? "No hay resultados." : "No results."}
													        />
													      </Form.Group>
												      :
												      	null
								      			}
							      			</>
						      			:
						      				null
					      			}
								      <Form.Group>
								        <Form.Label>{idioma ? "Municipio" : "Municipality"}:</Form.Label>
								        <Typeahead
								          id="basic-typeahead-multiple"
								          multiple
								          onChange={setMercadoMunicipio}
								          options={window._my_municipios}
								          selected={mercadoMunicipio}
								          emptyLabel={idioma ? "No hay resultados." : "No results."}
								        />
								      </Form.Group>
								      <div id="cl" className="minimo_espacio"></div>
							      	{idioma ? "Tipo de consulta" : "Query type"}:
								      <div className="flex_regiones">
						        		<Button variant={mercadoTipo==='mercados' ? "info" : "secondary"} onClick={() => {
						        			if(mercadoTipo==='mercados'){
						      					setMercadoTipo('')
						      				}else{
						        				setMercadoTipo('mercados')
						        			}
						        			setMercadoNombre([])
						        		}}>{idioma ? "Mercados" : "Markets"}</Button>
						      			<Button variant={mercadoTipo==='centrales' ? "info" : "secondary"} onClick={() => {
						        			if(mercadoTipo==='centrales'){
						      					setMercadoTipo('')
						      				}else{
						        				setMercadoTipo('centrales')
						        			}
						        			setMercadoNombre([])
						        		}}>{idioma ? "Centrales" : "Supply centers"}</Button>
					      			</div>
					      			{
					      				mercadoTipo==='mercados' ?
					      					<Form.Group>
										        <Form.Label>{idioma ? "Nombre" : "Nombre"}:</Form.Label>
										        <Typeahead
										        	id="basic-typeahead-multiple"
										          multiple
										          labelKey="nombre"
										          onChange={setMercadoNombre}
										          options={window._capa_mercados.filter(p => p.tipo===mercadoTipo)}
										          selected={mercadoNombre}
										          emptyLabel={idioma ? "No hay resultados." : "No results."}
										        />
										      </Form.Group>
									      :
									      	null
					      			}
								    </Form>
							    : buscar==='show_comercios' ?
							    	<Form>
				          		{
							      		arrayDenue.length>0 ?
							      			<div>
							      				<div className="f_right">{idioma ? "Resultados encontrados" : "Results found"}: <b>{arrayDenue.length}</b></div>
							      				<div id="cl"></div>
								      			<div className="flex_iconos_botones">
									      			{
									      				arrayDenue.length<2001 ?
											      			<CSVLink
																		headers={window._denue_header}
																		data={arrayDenue}
																		target="_blank"
																		//separator={"|"}
																		filename={"empresas_denue.csv"}
																		className="botones_iconos"
																	>
																		<img src="img/ico_excel.svg" title={idioma ? "Descargar CSV" : "Download CSV"} alt="CSV"/>
																	</CSVLink>
																:
																	null
															}
															<div className="botones_iconos" onClick={() => funcionGraficas(buscar)}>
																<img src="img/ico_grafica.svg" title={idioma ? "Ver gráficas" : "See graphs"} alt="Gráficas"/>
															</div>
															<div className="botones_iconos" id={queson==='establecimientos' ? 'marcado' : ''} onClick={() => {
																if(queson==='establecimientos'){
																	setQueson("")
																}else{
																	setQueson("establecimientos")
																}
															}}>
																<img src="img/ico_mapa.svg" title={idioma ? "Ver concentración por municipio" : "See concentration by municipality"} alt="Concentración"/>
															</div>
														</div>
													</div>
							      		:
							      			null
							      	}
							      	{
							      		localStorage._ROL ?
							      			<>
											    	{idioma ? "Tipo de región" : "Region type"}:
											      <div className="flex_regiones">
									        		<Button variant={region==='Macrorregión' ? "info" : "secondary"} onClick={() => {
									        			if(region==='Macrorregión'){
									      					setRegion('')
									      				}else{
									        				setRegion('Macrorregión')
									        			}
									        			setDenueRegion([])
									        			setDenueMunicipio([])
									        		}}>{idioma ? "Macrorregión" : "Macroregion"}</Button>
									      			<Button variant={region==='Microrregión' ? "info" : "secondary"} onClick={() => {
									      				if(region==='Microrregión'){
									      					setRegion('')
									      				}else{
									        				setRegion('Microrregión')
									        			}
									        			setDenueRegion([])
									        			setDenueMunicipio([])
									        		}}>{idioma ? "Microrregión" : "Microregion"}</Button>
								      			</div>
								      			{
								      				region ?
								      					<Form.Group>
													        <div id="mi_espacio"></div>
													        <Typeahead
													          id="basic-typeahead-multiple"
													          multiple
													          labelKey="regiones_lista"
													          onChange={(e) => {
													          	setDenueRegion(e)

													          	let array_pa_municipios = denueMunicipio
													          	if(e.length>0){
																      	e.map(item =>
																      		window._arreglo_coordenadas.filter(p => p.marcroregion===item || p.microregion===item).map(municipio => {  //eslint-disable-line
																      			if(array_pa_municipios.filter(p => p===municipio.municipio).length>0){
																      				array_pa_municipios = array_pa_municipios.filter(p => p===municipio.municipio)
																      			}else{
																      				array_pa_municipios.push(municipio.municipio)
																      			}
																      		})
																      	)
																      }else{
																      	array_pa_municipios = []
																      }
																      setDenueMunicipio(array_pa_municipios)
													          }}
													          options={region ? window._my_regiones.filter(p => p.nombre===region)[0].regiones_lista : window._my_todas_regiones}
													          selected={denueRegion}
													          emptyLabel={idioma ? "No hay resultados." : "No results."}
													        />
													      </Form.Group>
												      :
												      	null
								      			}
							      			</>
						      			:
						      				null
					      			}
								      <Form.Group>
								        <Form.Label>{idioma ? "Municipio" : "Municipality"}:</Form.Label>
								        <Typeahead
								          id="basic-typeahead-multiple"
								          multiple
								          onChange={setDenueMunicipio}
								          options={window._my_municipios}
								          selected={denueMunicipio}
								          emptyLabel={idioma ? "No hay resultados." : "No results."}
								        />
								      </Form.Group>
								      <Form.Group>
								        <Form.Label>{idioma ? "Busqueda rápida" : "Quick search"}:</Form.Label>
								        <Form.Control type="text" placeholder="Ej. oxxo" value={denueRapida} onChange={(event) => {setDenueRapida(event.target.value)}}/>
								      </Form.Group>
								      <Form.Group>
								        <Form.Label>{idioma ? "Actividad económica" : "Economic activity"}:</Form.Label>
								        <Typeahead
								          id="basic-typeahead-multiple"
								          multiple
								          labelKey="nombre_act"
								          onChange={setDenueActividad}
								          options={denue}
								          selected={denueActividad}
								          emptyLabel={idioma ? "No hay resultados." : "No results."}
								        />
								      </Form.Group>
								      <Form.Group>
								        <Form.Label>{idioma ? "Tamaño de la empresa" : "Size of the company"}:</Form.Label>
								        <Typeahead
								          id="basic-typeahead-multiple"
								          multiple
								          onChange={setDenueTamano}
								          options={window._tamano_empresa}
								          selected={denueTamano}
								          emptyLabel={idioma ? "No hay resultados." : "No results."}
								        />
								      </Form.Group>
								    </Form>
							    : buscar==='show_laboral' ?
							    	<Form>
				          		{
							      		arrayLaboral.length>0 ?
							      			<div>
							      				<div className="f_right">{idioma ? "Resultados encontrados" : "Results found"}: <b>{arrayLaboral.length}</b></div>
							      				<div id="cl"></div>
								      			<div className="flex_iconos_botones">
									      			{
									      				arrayLaboral.length<2001 ?
											      			<CSVLink
																		headers={window._laboral_header}
																		data={arrayLaboral}
																		target="_blank"
																		//separator={"|"}
																		filename={"laboral.csv"}
																		className="botones_iconos"
																	>
																		<img src="img/ico_excel.svg" title={idioma ? "Descargar CSV" : "Download CSV"} alt="CSV"/>
																	</CSVLink>
																:
																	null
															}
															<div className="botones_iconos" onClick={() => funcionGraficas(buscar)}>
																<img src="img/ico_grafica.svg" title={idioma ? "Ver gráficas" : "See graphs"} alt="Gráficas"/>
															</div>
															<div className="botones_iconos" id={queson==='trabajadores' ? 'marcado' : ''} onClick={() => {
																if(queson==='trabajadores'){
																	setQueson("")
																}else{
																	setQueson("trabajadores")
																}
															}}>
																<img src="img/ico_mapa.svg" title={idioma ? "Ver concentración por municipio" : "See concentration by municipality"} alt="Concentración"/>
															</div>
														</div>
													</div>
							      		:
							      			null
							      	}
							      	{
							      		localStorage._ROL ?
							      			<>
											    	{idioma ? "Tipo de región" : "Region type"}:
											      <div className="flex_regiones">
									        		<Button variant={region==='Macrorregión' ? "info" : "secondary"} onClick={() => {
									        			if(region==='Macrorregión'){
									      					setRegion('')
									      				}else{
									        				setRegion('Macrorregión')
									        			}
									        			setLaboralRegion([])
									        			setLaboralMunicipio([])
									        		}}>{idioma ? "Macrorregión" : "Macroregion"}</Button>
									      			<Button variant={region==='Microrregión' ? "info" : "secondary"} onClick={() => {
									      				if(region==='Microrregión'){
									      					setRegion('')
									      				}else{
									        				setRegion('Microrregión')
									        			}
									        			setLaboralRegion([])
									        			setLaboralMunicipio([])
									        		}}>{idioma ? "Microrregión" : "Microregion"}</Button>
								      			</div>
								      			{
								      				region ?
								      					<Form.Group>
													        <div id="mi_espacio"></div>
													        <Typeahead
													          id="basic-typeahead-multiple"
													          multiple
													          labelKey="regiones_lista"
													          onChange={(e) => {
													          	setLaboralRegion(e)

													          	let array_pa_municipios = laboralMunicipio
													          	if(e.length>0){
																      	e.map(item =>
																      		window._arreglo_coordenadas.filter(p => p.marcroregion===item || p.operativa===item || p.microregion===item).map(municipio => {  //eslint-disable-line
																      			if(array_pa_municipios.filter(p => p===municipio.municipio).length>0){
																      				array_pa_municipios = array_pa_municipios.filter(p => p===municipio.municipio)
																      			}else{
																      				array_pa_municipios.push(municipio.municipio)
																      			}
																      		})
																      	)
																      }else{
																      	array_pa_municipios = []
																      }
																      setLaboralMunicipio(array_pa_municipios)
													          }}
													          options={region ? window._my_regiones.filter(p => p.nombre===region)[0].regiones_lista : window._my_todas_regiones}
													          selected={laboralRegion}
													          emptyLabel={idioma ? "No hay resultados." : "No results."}
													        />
													      </Form.Group>
												      :
												      	null
								      			}
							      			</>
						      			:
						      				null
					      			}
								      <Form.Group>
								        <Form.Label>{idioma ? "Municipio" : "Municipality"}:</Form.Label>
								        <Typeahead
								          id="basic-typeahead-multiple"
								          multiple
								          onChange={setLaboralMunicipio}
								          options={window._my_municipios}
								          selected={laboralMunicipio}
								          emptyLabel={idioma ? "No hay resultados." : "No results."}
								        />
								      </Form.Group>
								      <Form.Group>
								        <Form.Label>{idioma ? "Fecha" : "Date"}:</Form.Label>
								        <div className="flex_input">
										        <Form.Select className="margin_selec" onChange={(event) => {
										        	setLaboralFechaAno(event.target.value)
										        }}>
										        	{
										        		window._anos_laboral.map((item, i) =>
									        				<option key={i} selected={laboralFechaAno===item ? true : false}>{item}</option>
									        			)
										        	}
										        </Form.Select>
										        <Form.Select onChange={(event) => {
										        	const selectedIndex = event.target.options.selectedIndex;
										        	//setLaboralFechaMes(event.target.value)
										        	setLaboralFechaMes(selectedIndex)
										        }}>
										        	{
										        		idioma ? 
											        		window.meses.map((item, i) =>
										        				<option key={i} selected={laboralFechaMes===i ? true : false}>{item}</option>
										        			)
									        			:
									        				window.mesesIn.map((item, i) =>
										        				<option key={i} selected={laboralFechaMes===i ? true : false}>{item}</option>
										        			)
										        	}
										        </Form.Select>
								      	</div>
								      </Form.Group>
								      <Form.Group>
								        <Form.Label>{idioma ? "División económica" : "Economic division"}:</Form.Label>
								        <Typeahead
								          id="basic-typeahead-multiple"
								          multiple
								          labelKey="_id"
								          onChange={setLaboralActividad}
								          options={division}
								          selected={laboralActividad}
								          emptyLabel={idioma ? "No hay resultados." : "No results."}
								        />
								      </Form.Group>
								      <Form.Group>
								        <Form.Label>{idioma ? "Tipo de trabajador" : "Worker type"}:</Form.Label>
								        <Typeahead
								          id="basic-typeahead-multiple"
								          multiple
								          onChange={setLaboralTipo}
								          options={window._trabajador_laboral}
								          selected={laboralTipo}
								          emptyLabel={idioma ? "No hay resultados." : "No results."}
								        />
								      </Form.Group>
								    </Form>
							    : buscar==='show_requisitos' && window._requisitos_medio.length>0 ?
							    	<div className="requisitos_ambiente">
							    		<div className="flex_requisitos" id="requisitos_quita">
							    			{
							    				window._requisitos_medio.map(item =>
						    						<div className={requisitos===item.id ? "req_si" : ""} onClick={() => setRequisitos(item.id)}>{idioma ? item.numero : item.numeroIn}</div>
						    					)
							    			}
							    		</div>
							    		<div className="flex_requisitos" id="requisitos_sale">
							    			{
							    				window._requisitos_medio.map(item =>
						    						<div className={requisitos===item.id ? "req_si" : ""} onClick={() => setRequisitos(item.id)}>{item.id}</div>
						    					)
							    			}
							    		</div>
							    		<div className="requisitos_descripcion">
							    			{
							    				window._requisitos_medio.filter(p => p.id===requisitos).map(item =>
						    						<div>
							    						<div className="requisitos_titulo">
							    							{idioma ? item.tramite : item.tramiteIn}
							    						</div>
							    						<div className="requisitos_descripcion">
							    							{idioma ? item.descripcion : item.descripcionIn}
							    						</div>
							    						<center>
								    						<a href={item.www} target={item.id===1 ? "_blank" : "new"} className="requisitos_www">
								    							<img src="img/www.svg" alt="www"/>
							    							</a>
							    						</center>
						    						</div>
						    					)
							    			}
							    		</div>
							    	</div>
						    	: buscar==='show_energia' && window._requisitos_energia.length>0 ?
							    	<div>
							    		<Accordion>
							    			{
							    				window._requisitos_energia.map((item, i) =>
							    					<Accordion.Item eventKey={i}>
											        <Accordion.Header className="acordeon_titulo">{idioma ? item.tramite : item.tramiteIn}</Accordion.Header>
											        <Accordion.Body>
											        	{
											        		item.objetivo ?
											        			<div><div dangerouslySetInnerHTML={{ __html: idioma ? item.objetivo : item.objetivoIn }} className="texto_modal"/><br/></div>
										        			:
										        				null
											        	}
											        	{
											        		item.servicios.length>0 ?
											        			<table className="tabla_aeeh">
												        			{
													        			item.servicios.map((ser, n) =>
													        				<tr key={n}>
													        					<td>
													        						{idioma ? ser.concepto : ser.conceptoIn}
													        					</td>
													        					<td>
													        						{idioma ? ser.descripcion : ser.descripcionIn}
													        					</td>
													        					{
													        						ser.liga ?
													        							<td>
														        							<a href={ser.liga} target="new" className="requisitos_www">
																	    							<img src="img/www.svg" alt="www"/>
																    							</a>
															    							</td>
													        						:
													        							null
													        					}
													        				</tr>
												        				)
													        		}
										        				</table>
											        		:
											        			null
											        	}
											        	{
											        		item.liga ?
											        			<center>
											        				<br/>
											    						<a href={item.liga} target="new" className="requisitos_www">
											    							<img src="img/www.svg" alt="www"/>
										    							</a>
										    						</center>
											        		:
											        			null
											        	}
											        </Accordion.Body>
										        </Accordion.Item>
						    					)
							    			}
							        </Accordion>
							    	</div>
						    	: buscar==='show_circulo' ?
					        	<div>
					        		{
					        			circuloEsc.length>0 || circuloDenue.length>0 || circuloMercados.length>0 || limpiar>0 || checkCircleInMarker({lat: miUbicacion.latitude, lng: miUbicacion.longitude}, circuloInfo) ?
								     			<div>
								     				{
								     					checkCircleInMarker({lat: miUbicacion.latitude, lng: miUbicacion.longitude}, circuloInfo) ?
						     								checkCircleInMarker({lat: miUbicacion.latitude, lng: miUbicacion.longitude}, circuloInfo) ?
						     									<table class="tabla_circulo">
						     										<tr>
						     											<th>{idioma ? 'Mi ubicación' : 'My location'}</th>
				     												</tr>
				     												<tr onClick={() => {
							     										setActiveMarker("mi_ubicacion")
							     										cerrarModal()
							     									}}>
						     											<td>{idioma ? 'Ver mi ubicación' : 'See my location'}</td>
				     												</tr>
					     										</table>
						     								:
						     									null
								     					:
								     						null 
								     				}
								     				{
								     					window._my_capas.filter(p => p.tipo==='marker').length>0 ?
																window._my_capas.filter(p => p.tipo==='marker').map(item => { //eslint-disable-line
																	if(eval(item.id)>0){ //eslint-disable-line
																		if(item.arreglo.filter(p => checkCircleInMarker({lat: p.lat, lng: p.lng}, circuloInfo)).length>0){
																			return(
																				<table class="tabla_circulo">
																					<tr>
																						<th colspan="2">{idioma ? item.nombre : item.nombreIn} ({item.arreglo.filter(p => checkCircleInMarker({lat: p.lat, lng: p.lng}, circuloInfo)).length}):</th>
																					</tr>
																					{
																						item.arreglo.filter(p => checkCircleInMarker({lat: p.lat, lng: p.lng}, circuloInfo)).map(marca =>
																							<tr onClick={() => {
																								if(marca.infowindow || item.infowindow){
																									setActiveMarker(`${item.id}_${marca.id}`)
																									cerrarModal()
																								}else{
																									setModal(true)
																									setBuscar("")
  																								setCirculoInfo(null)
																									if(marca.informacion){
																										if(idioma){
																											setInformacion(marca.informacion)
																										}else{
																											setInformacion(marca.informacionIn)
																										}
																									}else if(marca.fotografia){
																										setFotografia(marca.fotografia)
																									}
																								}
																							}}>
										     												<td>{item.id==='Capa_Presidencias' ? idioma ? `Presidencia municipial de ${marca.municipio}` : `Municipal Presidency of ${marca.municipio}` : idioma ? marca.nombre : marca.nombreIn}</td>
																								<td className="td_chico">
																									<center><img src={item.imagen} title={idioma ? 'Ver' : 'Show'} alt="Buscar"/></center>
																								</td>
																							</tr>
																						)
																					}
																				</table>
																			)
																		}
																	}
																})
															:
																null
								     				}
								     				{
								     					circuloEsc.length>0 ?
								     						<table class="tabla_circulo">
								     							<tr>
								     								<th colspan="3">{idioma ? "Planteles educación media superior" : "Universities and high schools"} ({circuloEsc.length}):</th>
								     							</tr>
								     							{
								     								circuloEsc.map((item, i) => 
							     										<tr onClick={() => {
								     										setActiveMarker(`show_escuelas_${item.es_id}`)
								     										cerrarModal()
								     									}}>
									     									<td>{item.es_escuela}</td>
									     									<td>{item.municipio}</td>
									     									<td className="td_chico">
									     										<center><img src="img/iconos/escuelas.svg" title={idioma ? 'Ver' : 'Show'} alt="Escuelas"/></center>
								     										</td>
							     										</tr>
							     									)
								     							}
								     						</table>
								     					:
							     							null
								     				}
								     				{
								     					circuloDenue.length>0 ?
								     						<table class="tabla_circulo">
								     							<tr>
								     								<th colspan="3">{idioma ? "Empresas y establecimientos" : "Companies and establishments"} ({circuloDenue.length}):</th>
								     							</tr>
								     							{
								     								circuloDenue.map((item, i) => 
							     										<tr onClick={() => {
							     											if(arrayDenue.length>500){
							     												let loly_arreglo = markerNew
							     												if(loly_arreglo.filter(p => p.id===item.id).length>0){
							     													loly_arreglo = loly_arreglo.filter(p => p.id!==item.id)
							     												}else{
							     													loly_arreglo.push(item)
							     												}
							     												setMarkerNew(loly_arreglo)
							     												cerrarModal()
							     											}else{
									     										setActiveMarker(`show_comercios_${item.id}`)
									     										cerrarModal()
									     									}
								     									}}>
									     									<td>{item.nom_estab}</td>
									     									<td>{item.municipio}</td>
									     									<td className="td_chico" id={markerNew.filter(p => p.id===item.id).length>0 ? 'tr_color' : null}>
									     										<center><img src="img/icos/establecimientos.png" title={idioma ? 'Ver' : 'Show'} alt="Establecimientos"/></center>
								     										</td>
							     										</tr>
							     									)
								     							}
								     						</table>
								     					:
							     							null
								     				}
								     				{
								     					circuloMercados.length>0 ?
								     						<table class="tabla_circulo">
								     							<tr>
								     								<th colspan="3">{idioma ? "Mercados y centrales" : "Markets and supply centers"} ({circuloMercados.length}):</th>
								     							</tr>
								     							{
								     								circuloMercados.map((item, i) => 
							     										<tr onClick={() => {
								     										setActiveMarker(`show_mercados_${item.id}`)
								     										cerrarModal()
								     									}}>
									     									<td>
									     										{
																	      		mercadoTipo==='mercados' ?
																	      			<div>{idioma ? "Mercado" : "Market"} {item.nombre}</div>
																      			:
																      				<div>{idioma ? "Central" : "Supply center"} {item.municipio}</div>
																      		}
																      	</td>
																      	<td>{item.municipio}</td>
									     									<td className="td_chico">
									     										<center><img src="img/icos/mercados.png" title={idioma ? 'Ver' : 'Show'} alt="Mercados"/></center>
								     										</td>
							     										</tr>
							     									)
								     							}
								     						</table>
								     					:
							     							null
								     				}
								     			</div>
							      		:
							      			idioma ? "No hay información." : "There is no information."
					        		}
					        	</div>
						    	:
							    	idioma ? "No hay información." : "There is no information."
							  }

							  {
		          		window._my_capas.filter(p => p.id===buscar).length>0 ?
		          			<div className="fuente_leyenda">{window._my_capas.filter(p => p.id===buscar)[0].fuente}</div>
		          		:
		          			null
		          	}
        			</div>
          }
        </Modal.Body>
        {
      		buscar==='show_indicadores' ?
      			<Modal.Footer>
		          <Button variant="secondary" onClick={() => {
			        	setIndicadoresVariable([])
			        	setIndicadoresRegion([])
			        	setIndicadoresMunicipio([])
			        	setArrayIndicadores([])
			        	setIndica([])
			        	setIndicadorPrincipal("")
			        }}>
		            {idioma ? "Limpiar" : "Clean"}
		          </Button>
		          {
		          	window._capa_indicadores.filter(p => eval(condicion_indicadores)).length>0 ? //eslint-disable-line
				          <Button variant="success" onClick={() => {
					        	const arreglo_consulta = window._capa_indicadores.filter(p => eval(condicion_indicadores)) //eslint-disable-line
					        	setModal(false)
					        	setBuscar("")
					        	setArrayIndicadores(arreglo_consulta)
					        	setQueson(indicadores)
					        	setIndica(indicadoresVariable)
					        	setIndicadorPrincipal(indicadores)
					        	functionZoom()
					        }}>
				            {idioma ? "Consultar" : "Find"} ({
				            	window._capa_indicadores.filter(p => eval(condicion_indicadores)).length //eslint-disable-line
				            })
				          </Button>
			          :
			          	<Button variant="warning" onClick={() => {
					        	setModal(false)
					        	setBuscar("")
					        	setArrayIndicadores([])
					        	setIndica([])
					        	setIndicadorPrincipal("")
					        }}>
					        	{idioma ? "No hay información con esos criterios" : "There is no information with that data"}
				        	</Button>
			        }
		        </Modal.Footer>
        	: buscar==='show_escuelas' ?
	        	<Modal.Footer>
		          <Button variant="secondary" onClick={() => {
			        	setCarreraEscuelas([])
			        	setInstitucionEscuelas([])
			        	//setEscuelaEscuelas([])
			        	setRegionEscuelas([])
			        	setMunicipioEscuelas([])
			        	setArrayEsc([])
			        }}>
		            {idioma ? "Limpiar" : "Limpiar"}
		          </Button>
		          {
		          	window._capa_escuelas.filter(p => eval(condicion_escuelas)).length>0 ? //eslint-disable-line
				          <Button variant="success" onClick={() => {
					        	const arreglo_consulta = window._capa_escuelas.filter(p => eval(condicion_escuelas)) //eslint-disable-line
					        	setModal(false)
					        	setBuscar("")
					        	setArrayEsc(arreglo_consulta)
					        	setQueson("escuelas")
					        	functionZoom()
					        }}>
				            {idioma ? "Consultar" : "Find"} ({
				            	window._capa_escuelas.filter(p => eval(condicion_escuelas)).length //eslint-disable-line
				            })
				          </Button>
			          :
			          	<Button variant="warning" onClick={() => {
					        	setModal(false)
					        	setBuscar("")
					        	setArrayEsc([])
					        }}>
					        	{idioma ? "No hay información con esos criterios" : "There is no information with that data"}
				        	</Button>
			        }
		        </Modal.Footer>
	        : buscar==='show_mercados' ?
	        	<Modal.Footer>
		          <Button variant="secondary" onClick={() => {
			        	setMercadoNombre([])
			        	setMercadoRegion([])
			        	setMercadoMunicipio([])
			        	setArrayMercados([])
			        }}>
		            {idioma ? "Limpiar" : "Limpiar"}
		          </Button>
		          {
		          	window._capa_mercados.filter(p => eval(condicion_mercados)).length>0 ? //eslint-disable-line
				          <Button variant="success" onClick={() => {
					        	const arreglo_consulta = window._capa_mercados.filter(p => eval(condicion_mercados)) //eslint-disable-line
					        	setModal(false)
					        	setBuscar("")
					        	setArrayMercados(arreglo_consulta)
					        	setQueson(mercadoTipo)
					        	functionZoom()
					        }}>
				            {idioma ? "Consultar" : "Find"} ({
				            	window._capa_mercados.filter(p => eval(condicion_mercados)).length //eslint-disable-line
				            })
				          </Button>
			          :
			          	<Button variant="warning" onClick={() => {
					        	setModal(false)
					        	setBuscar("")
					        	setArrayMercados([])
					        }}>
					        	{idioma ? "No hay información con esos criterios" : "There is no information with that data"}
				        	</Button>
			        }
		        </Modal.Footer>
	        : buscar==='show_comercios' ?
	        	<Modal.Footer>
		          <Button variant="secondary" onClick={() => {
			        	setArrayDenue([])
			        	setDenueRapida([])
								setDenueRegion([])
								setDenueMunicipio([])
								setDenueActividad([])
								setDenueTamano([])
						    setArrayMunicipiosDenue([])
						    setMayorDenue(0)
						    setMarkerNew([])
			        }}>
		            {idioma ? "Limpiar" : "Limpiar"}
		          </Button>
		          <Button variant="success" onClick={() => {
			        	window.H5_loading.show();
						    var params = new URLSearchParams();
						    params.append('cual', 'denue_consulta');

						    params.append('denueRapida', denueRapida);
						    //params.append('region', region);
						    //params.append('denueRegion', denueRegion);
						    params.append('denueMunicipio', JSON.stringify(denueMunicipio));
						    params.append('denueActividad', JSON.stringify(denueActividad));
						    params.append('denueTamano', JSON.stringify(denueTamano));
						    Axios.post(`${window.ws}wsSelect.php`, params)
						      .then(response => {
						        if(response.data.elError===1){
						        	setModal(false)
						        	setBuscar("")
						        	setQueson("establecimientos")
						          //alerta('success',''+response.data.mensaje);
						          setArrayDenue(response.data.lista_array)
						          setArrayMunicipiosDenue(response.data.lista_municipios)
						          setMayorDenue(response.data.elMayor)
						          functionZoom()
						          setMarkerNew([])
						          //console.log(response.data.lista_array)
						        }else{
						          alerta('error',''+response.data.mensaje);
						        }
						      })
						      .catch(error => {
						        //alerta('error','LOLERO'+error);
						        console.log(error)
						      })
						      .then(function() {
						        window.H5_loading.hide();
						      })
			        }}>
		            {idioma ? "Consultar" : "Find"}
		          </Button>
		        </Modal.Footer>
	        : buscar==='show_laboral' ?
	        	<Modal.Footer>
		          <Button variant="secondary" onClick={() => {
			        	setArrayLaboral([])
			        	setLaboralFechaAno(window.ano)
								setLaboralFechaMes(0)
								setLaboralRegion([])
								setLaboralMunicipio([])
								setLaboralActividad([])
						    setLaboralTipo([])
						    //setMayorDenue(0)
			        }}>
		            {idioma ? "Limpiar" : "Limpiar"}
		          </Button>
		          <Button variant="success" onClick={() => {
			        	window.H5_loading.show();
						    //var params = new URLSearchParams();

						    let mes_1 = laboralFechaMes+1;
                if(mes_1<10){
                    mes_1 = "0"+mes_1
                }
                const fecha_enviar = laboralFechaAno+"-"+mes_1;

						    const consultar = {
							    fecha: fecha_enviar,
							    municipio: laboralMunicipio,
							    division: laboralActividad,
							    tipo: laboralTipo,
								}

						    Axios.post(`${window.ruta2}w_mapaLaboral`, consultar)
						      .then(response => {
						        if(response.data.elError===1){
						        	setModal(false)
						        	setBuscar("")
						        	setQueson("trabajadores")
						          //alerta('success', laboralMunicipio.length+'perro: '+response.data.msj+' lala:'+response.data.basemongo.length);
						          //console.log(response.data.basemongo)
						          setArrayLaboral(response.data.basemongo)
						          //setArrayMunicipiosDenue(response.data.lista_municipios)
						          //setMayorDenue(response.data.elMayor)
						          functionZoom()
						          //console.log(response.data.lista_array)
						        }else if(response.data.elError===3){
						        	alerta('error', 'Un campo requerido está vacío');
						        }else{
						          alerta('error', 'No se encontraron resultados');
						          setArrayLaboral([])
						        }
						      })
						      .catch(error => {
						        //alerta('error','LOLERO'+error);
						        console.log(error)
						      })
						      .then(function() {
						        window.H5_loading.hide();
						      })
			        }}>
		            {idioma ? "Consultar" : "Find"}
		          </Button>
		        </Modal.Footer>
	        : 
	        	null
        }
      </Modal>

      <Modal
        show={graficasModal}
        onHide={() => {
        	setGraficasModal(false)
        	if(modal===false){
        		setBuscar("")
        	}
        }}
        size="lg"
        centered
      >
        {
        	buscar ?
	        	<Modal.Header closeButton>
		          <Modal.Title>
		          	{
		          		buscar==='show_indicadores' ?
		          			idioma ? indicadorPrincipal : "Graphics"
		          		: window._my_capas.filter(p => p.id===buscar).length>0 ?
	          				idioma ? window._my_capas.filter(p => p.id===buscar)[0].nombre : window._my_capas.filter(p => p.id===buscar)[0].nombreIn
		          		:
		          			null
		          	}
		          </Modal.Title>
		        </Modal.Header>
	        :
	        	null
        }
        <Modal.Body>
        	<div className="div_modal_bot">
        		{
        			grafica_1.length>0 ?
        				<Chart
                  width="100%"
                  height="400px"
                  chartType="PieChart"
                  loader={idioma ? "Cargando..." : "Loading..."}
                  data={grafica_1}
                  options={{
                    title: idioma ? "Municipios" : "Municipalities",
                    //is3D: true,
                    //sliceVisibilityThreshold: 0.05, // 5%
                    backgroundColor: {
								      fill: '#fff',
								      fillOpacity: 0.4
								    },
                  }}
                />
      				:
      					null
      			}
      			{
        			grafica_2.length>0 ?
        				<Chart
                  width="100%"
                  height="400px"
                  chartType="PieChart"
                  loader={idioma ? "Cargando..." : "Loading..."}
                  data={grafica_2}
                  options={{
                    title: buscar==='show_indicadores' ? idioma ? "Indicadores" : "Indicators" : idioma ? "Carrera" : "Career",
                    //is3D: true,
                    sliceVisibilityThreshold: 0.01, // 1%
                    backgroundColor: {
								      fill: '#fff',
								      fillOpacity: 0.4
								    },
                  }}
                />
      				:
      					null
      			}
        	</div>
      	</Modal.Body>
    	</Modal>
    	<Modal
        show={modalParque>0 ?  true : false}
        onHide={() => {
        	setModalParque(0)
        	tabla_total = 0
        	tabla_total_pea = 0
      	}}
        size="lg"
        centered
        className="my-modal my-modal-parque"
      >
      	{
      		modalParque>0 ?
      			<>
      				<Modal.Header closeButton>
			          <Modal.Title>
				          <h12>{idioma ? "Parque industrial" : "Industrial park"} {window._my_capas.filter(p => p.id==='Capa_parques')[0].arreglo.filter(p => p.id===modalParque)[0].nombre}</h12>
				          <h13>{idioma ? "Análisis de población en un radio de 25 km" : "Population analysis within a radius of 25 km"}</h13>
			          </Modal.Title>
			        </Modal.Header>
			      	<Modal.Body>
			        	<div className="flex_parques">
			        		<img src={window._my_capas.filter(p => p.id==='Capa_parques')[0].arreglo.filter(p => p.id===modalParque)[0].mapita} alt="Park"/>
					      	<div className="div_parques">
						      	{
						      		window._my_capas.filter(p => p.id==='Capa_parques')[0].arreglo.filter(p => p.id===modalParque)[0].pea.map((item, i) => { //eslint-disable-line
			      						item.datos.map((mun, n) => { //eslint-disable-line
			      							tabla_total += mun.poblacion
  												tabla_total_pea += mun.pea
  												if(mun.marcar){
  													titulo_municipio = mun.municipio
														titulo_pea = mun.pea
  												}
			      						})
						      		})
						      	}
						      	{
						      		idioma ?
						      			<div className="parques_leyenda">
						      				Total de Población Económicamente Activa (PEA): {window.formatoMexico(tabla_total_pea)} personas.
						      				<br/>
						      				{titulo_municipio} representa {Math.round(100*(titulo_pea/tabla_total_pea))}% de la PEA en el área con una mano de obra disponible de {window.formatoMexico(titulo_pea)} personas.
						      			</div>
						      		:
						      			<div className="parques_leyenda">
						      				Total de Población Económicamente Activa (PEA): {window.formatoMexico(tabla_total_pea)} personas.
						      				<br/>
						      				Villa de Tezontepec  representa 1% de la PEA en el área con una mano de obra disponible de 8,327 personas.
						      			</div>
						      	}
						      	<table className="tabla_parques">
							      	{
							      		window._my_capas.filter(p => p.id==='Capa_parques')[0].arreglo.filter(p => p.id===modalParque)[0].pea.map((item, i) => {
							      			tabla_sub_total = 0
													tabla_sub_total_pea = 0
							      			return(
							      				<>
							      					{
							      						i===0 ?
							      							<tr>
									      						<th rowspan={item.datos.length+2} className="th_vertical"><div>{item.estado}</div></th>
									      						<th className="tama_td_parques">{idioma ? "Municipios" : "Municipalities"}</th>
									      						<th>{idioma ? "Población" : "Population"}</th>
									      						<th>{idioma ? "PEA" : "PEA"}</th>
									      					</tr>
								      					:
								      						<tr>
									      						<th rowspan={item.datos.length+2} className="th_vertical"><div>{item.estado}</div></th>
									      						<th></th>
									      						<th></th>
									      						<th></th>
									      					</tr>
							      					}
							      					{
							      						item.datos.map((mun, n) => {
							      							tabla_sub_total += mun.poblacion
							      							tabla_sub_total_pea += mun.pea
							      							return(
						      									<tr key={n} className={mun.marcar ? 'parque_marcado' : null}>
										      						<td className="tama_td_parques">{mun.municipio}</td>
										      						<td>{window.formatoMexico(mun.poblacion)}</td>
										      						<td>{window.formatoMexico(mun.pea)}</td>
										      					</tr>
						      								)
							      						})
							      					}
							      					<tr>
							      						<th>Subtotal:</th>
							      						<th>{window.formatoMexico(tabla_sub_total)}</th>
							      						<th>{window.formatoMexico(tabla_sub_total_pea)}</th>
							      					</tr>
							      				</>
						      				)
							      		})
							      	}
			      					<tr className="parques_total">
			      						<th></th>
			      						<th>TOTAL:</th>
			      						<th>{window.formatoMexico(tabla_total)}</th>
			      						<th>{window.formatoMexico(tabla_total_pea)}</th>
			      					</tr>
			      				</table>
					      	</div>
				      	</div>
			      	</Modal.Body>
      			</>
    			:
    				null
      	} 
      </Modal>
		</div>
	);
}

export default Carta;