import { useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

//import Inicio from './components/Inicio'
import Inicio from './components/Carta'
import Carta from './components/Carta'

import ReactGA from 'react-ga';
const TRACKING_ID = "UA-231287964-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  let initialized = false
  useEffect(() => {
    if(!initialized){
      ReactGA.pageview(window.location.pathname + window.location.search);
      initialized = true //eslint-disable-line
    }
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Inicio} />
        <Route path={window.path+"/inicio"} component={Inicio} />
        <Route path={window.path+"/carta"} component={Carta} />
        <Route component={Inicio} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
